{
  "zapscale_users": {
    "customer_success": "CS Team",
    "tech_and_development": "Tech & Product",
    "sales": "Sales & Marketing",
    "finance": "Finance",
    "training_and_development": "Training & Support",
    "invite_new_user": "Invite New User",
    "edit_invited_user": "Edit Invited User",
    "others": "Others"
  },
  "u1_roles": {
    "cxo_and_director": "CXO and Director",
    "vp": "VP",
    "head": "Head",
    "manager": "Manager",
    "team_member": "Team Member"
  },
  "task_status": {
    "new": "New",
    "done": "Done",
    "working": "Working"
  },
  "task_priority": {
    "high": "High",
    "medium": "Medium",
    "low": "Low"
  },
  "message_priority": {
    "low": "Low",
    "medium": "Mid",
    "high": "High"
  },
  "task": "Task | Task(1) | Tasks({count})",
  "message": "Message | Message(1) | Messages({count})",
  "action": "Action | Action(1) | Actions({count})",
  "todo_step": "TODO STEP | TODO STEP | TODO STEPS",
  "success_plans": "Success Plans",
  "success_plan_name": "Success Plan Name",
  "new": "%{new} New",
  "overdue": "%{overdue} Overdue",
  "working": "%{working} Working",
  "done": "%{done} Done",
  "bad": "%{bad}% Bad",
  "fair": "%{fair}% Fair",
  "good": "%{good}% Good",
  "inactive": "%{inactive}% Inactive",
  "me": "Me",
  "filters": "Filters",
  "customer_search": "Customer Search",
  "select_csm": "Select CSM",
  "select_account_manager": "Select account manager",
  "select_sales_person": "Select sales person",
  "status": "Status",
  "priority": "Priority",
  "no_record_found": "No record found",
  "task_creator": "Task Creator",
  "message_creator": "Message Creator",
  "assigned_to": "Assigned To",
  "messages": "Messages",
  "create_message": "Create Message",
  "topic": "Topic",
  "task_name": "Task Name",
  "message_name": "Message Topic",
  "action_name": "Action Topic",
  "task_description": "Task Description",
  "select_customer": "Select Customer",
  "target_date": "Target Date",
  "created_at": "Creation Date",
  "create": "Create",
  "cancel": "Cancel",
  "software_usage": "Software Usage",
  "total_hours_of_usage": "Total Hours of Usage",
  "mins_customer_time": "Mins - customer/%{time}",
  "mins_user_time": "Mins - user/%{time}",
  "pages_visited": "Pages Visited",
  "total_pages_visited": "Total pages visited",
  "pages_customer_time": "Pages - customer/%{time}",
  "features_customer_time": "Features - customer/%{time}",
  "bugs_customer_time": "Tickets - customer/%{time}",
  "pages_user_time": "Pages - user/%{time}",
  "feature_used": "Feature Used",
  "total_features_used": "Total Features Used",
  "per_customer_time": "Per customer/%{time}",
  "user_time": "User/%{time}",
  "bugs_logged": "Tickets Logged",
  "tasks": "Tasks",
  "total_task_done": "Total Tasks Done",
  "tasks_user_time": "Tasks user/%{time}",
  "messages_handled": "Messages Handled",
  "messages_user_time": "Messages user/%{time}",
  "actions": "Actions",
  "actions_done": "Actions Done",
  "actions_user_time": "Actions - user/%{time}",
  "actions_time": "Actions Time",
  "hours_spent": "Hours spent",
  "customer_user_stats": "Customer & User Stats",
  "team_stats": "Team Stats",
  "time_spent_in_zapscale": "Time Spent in Zapscale",
  "hours": "Hours",
  "no_of_customer": "No. of Customer",
  "customer_health": "Customer health",
  "total_arr": "Total ARR",
  "net_retention": "Net Retention",
  "net_dollar_retention": "Net Dollar Retention",
  "net_churn": "Net Churn",
  "churned_customers": "Churned Customers",
  "dollar_churned": "Dollar Churned",
  "no_of_users": "No. of Users",
  "user_health": "User Health",
  "avg_customer_zapscore": "Avg Customer Zapscore",
  "no_of_tasks": "No. of Tasks",
  "no_of_messages": "No. of Messages",
  "no_of_actions": "No. of Actions",
  "no_of_hours_in_actions": "No of Hours in Actions",
  "no_of_hours_on_zapscale": "No of Hours on ZapScale",
  "total_time_of_usage": "Total time of Usage",
  "day": "day",
  "week": "week",
  "month": "month",
  "quarter": "quarter",
  "year": "year",
  "downgrades": "Downgrades",
  "steady": "Steady",
  "upgrades": "Upgrades",
  "churn_probability": "Churn Probability",
  "view_context": "View Context",
  "from": "From",
  "to": "To",
  "description": "Description",
  "suggestions": "Suggestions",
  "updated_successfully": "Updated successfully",
  "something_went_wrong": "Something went wrong",
  "validations": {
    "vRequired": "Cannot be blank",
    "vMinLength": "Must have at least {min} characters",
    "vMaxLength": "Cannot have more than {max} characters",
    "vSameAs": "Does not match {otherName}",
    "vUrl": "Is not a valid URL",
    "vInvalidEmail": "Invalid email",
    "vSelectOne": "Select an option",
    "vEmail": "Must be a valid email address",
    "vPositive": "Positive value required",
    "vMinOptions": "At least one option must be selected"
  },
  "saved": "Saved",
  "when_zapbook_red": "When Zapbook Is Red",
  "start": "Start",
  "stop": "Stop",
  "set_condition_is": "Set Condition (Is)",
  "for": "for",
  "create_task": "Create Task",
  "task_for": "Task for",
  "message_for": "Message for",
  "message_topic": "Topic",
  "target": "Target",
  "email": "Email",
  "send_email_to": "Send Email to",
  "subject": "Subject",
  "body": "Body",
  "playbook_steps": "Playbook Steps",
  "step": "Step %{count}",
  "help_guide": "Help Guide",
  "exception_handling": "Exception Handling",
  "your_weight": "Your Weight",
  "exception_handling_playbooks": "Exception Handling Playbooks",
  "days": "1 Day | {count} Days",
  "weeks": "1 Week | {count} Weeks",
  "months": "1 Month | {count} Months",
  "quarters": "1 Quarter | {count} Quarters",
  "years": "1 Year | {count} Years",
  "add_steps": "Add Step",
  "and_condition": "And Condition",
  "or_condition": "Or Condition",
  "your_business": "Your Business",
  "customer": "Customer| Customer | Customers",
  "user": "User",
  "email_priority": {
    "low": "Low",
    "medium": "Mid",
    "high": "High"
  },
  "save": "Save",
  "delete": "Delete",
  "overview": "Overview",
  "crm": "CRM",
  "crm_title": "CRM (Customer Relationship Management)",
  "bug_tracking": "Ticket Tracking",
  "ticketing": "Ticketing",
  "email_system": "Email System",
  "comm_system": "Comm System",
  "product_usage": "Product Usage",
  "data_warehouse": "Data Warehouse",
  "feature_requests": "Feature Requests",
  "payment_system": "Subscription & Billing",
  "kpis_and_playbooks": "KPI's & Playbooks",
  "peripheral_apps_greeter": {
    "part1": "Now let's integrate your CRM, Ticket Tracking, Emails, Subscription Payment Systems, Feature Request Systems to get more signals about your customers' health. ZapScale comes with",
    "part2": "40 Out of the Box KPI's and 50+ Pre-build Playbook",
    "part3": ". Let's go!"
  },
  "integrate_peripheral_app": "Do you wish to integrate {appType} to Zapscale?",
  "choose_peripheral_app_platform": "Choose Your {appType} Platform",
  "no_peripheral_app_found": "Oops..!! No peripheral app found",
  "name": "Name",
  "kpi_health": "KPI Health",
  "on_off": "On/Off",
  "integrate": "Integrate",
  "edit_integration": "Edit Integration",
  "peripheral_app_integration_success": "{appName} has been successfully integrated",
  "peripheral_app_integration_failure": "Failed to integrate peripheral app",
  "peripheral_app_status_update_success": "Successfully updated app status",
  "kpis": "KPIs",
  "kpi_not_found": "{kpiName}  KPI not found..!!",
  "peripheral_success_trend": "Peripheral Success Trend",
  "kpi_score": "KPI Score",
  "no_of_bugs_logged": "No. of tickets logged",
  "no_of_u2_logging_bugs": "No. of U2s logging tickets",
  "no_of_bugs_P1": "No. of P1 tickets",
  "no_of_bugs_resolved": "No. of tickets resolved",
  "no_of_feature_requests": "No. of feature requests",
  "no_of_features_resolved": "No. of features resolved",
  "no_of_payments_full": "No. of full payments",
  "no_of_payments_paid": "No. of payments on time",
  "total_no_of_overdue": "Total no. of overdues",
  "no_of_emails_or_communication": "No. of emails or communications",
  "days_since_last_communication": "Days since last communication",
  "stvcs_total_sessions_time": "Total sessions time",
  "pvvcs_volume_consistency_score": "Total no. of page visits",
  "pvps_total_no_of_unique_pages_visited": "Total no. of unique pages visited",
  "fups_total_no_of_unique_features_used": "Total no. of unique features used",
  "department": "Department",
  "role": "Role",
  "percentile": "Percentile",
  "upsell_success_trend": "Upsell Success Trend",
  "renewal_day_count": "0 Day | 1 Day | {count} Days",
  "renewal_days": "Till Renewal",
  "renewal_days_count_past": "0 Day Since Expired | 1 Day Since Expired| {count} Days Since Expired",
  "renewal_days_count_future": "0 Day Till Renewal| 1 Day Till Renewal| {count} Days Till Renewal",
  "expired_days": "Since Expired",
  "set_commercial_plans": "Set Commercial Plans",
  "upsell_kpis": "Upsell Kpi's",
  "upsell_greeter": "Now, Upsell is an important CS objective. We need to know about your commercial plans so that we can activate the 7 Upsell KPIs + 6 playbooks to help you with achieving high upsell.",
  "add_action": "Add Action",
  "add": "Add",
  "action_type": "Action Type",
  "time_taken": "Time taken",
  "details": "Details",
  "action_type_enum": {
    "call": "Call",
    "email": "Email",
    "meeting": "Meeting",
    "message": "Message",
    "task": "Task",
    "qbr_meeting": "QBR Meeting",
    "int_meeting": "Internal Meeting"
  },
  "action_on": "Action On",
  "mins": "{count} mins",
  "filter_by": "Filter By",
  "clear": "Clear",
  "created_by": "Created by",
  "select_user": "Select User",
  "user_search": "User Search",
  "create_new": "Create New",
  "webhook_url": "Webhook Url",

  "peripheral_app_names": {
    "salesforce": "Salesforce",
    "hubspot_crm": "Hubspot CRM",
    "hubspot_crm_accounts": "Hubspot CRM (Extraction via Accounts)",
    "hubspot_crm_deals": "Hubspot CRM (Extraction via Deals)",
    "hubspot_bug": "Hubspot",
    "hubspot_feature": "Hubspot",
    "pipedrive": "Pipedrive",
    "sugar_crm": "Sugar CRM",
    "ms_dynamics": "MS Dynamics",
    "freshdesk_bug": "Freshdesk",
    "freshdesk_feature": "Freshdesk",
    "zendesk_bug": "Zendesk",
    "zendesk_feature": "Zendesk",
    "zoho_crm": "Zoho CRM",
    "zohodesk_bug": "Zohodesk",
    "zohodesk_feature": "Zohodesk",
    "jira_bug": "Jira",
    "jira_feature": "Jira",
    "stripe_as_pricing": "Stripe",
    "stripe_as_product": "Stripe",
    "paypal": "Paypal",
    "square": "Square",
    "chargebee": "Chargebee",
    "quickbooks": "Quickbooks",
    "gmail": "Gmail",
    "outlook": "Outlook",
    "mailbox": "Mailbox",
    "redmine": "Redmine",
    "zohobooks": "Zohobooks",
    "helpscout_bug": "Help Scout",
    "helpscout_feature": "Help Scout",
    "no_name": "No Name",
    "csv_ticketing": "Ticketing CSV Integration",
    "csv_crm": "CRM CSV Integration",
    "csv_feature_request": "Feature Request CSV Integration",
    "csv_payment_system": "Subscription & Billing System CSV Integration",
    "zoho_subscriptions": "Zoho Subscriptions",
    "intercom_comm": "Intercom",
    "slack": "Slack",
    "ms_teams": "MS Teams",
    "whatsapp": "WhatsApp",
    "intercom_bug": "Intercom",
    "intercom_feature": "Intercom",
    "slack_notification": "Add ZapScale App to your Slack Channel",
    "slack_two_way": "Integrate your Customer Slack Channels",
    "segment": "Segment",
    "mixpanel": "Mixpanel",
    "salesforce_cloud_bug": "Salesforce Cloud",
    "salesforce_cloud_feature": "Salesforce Cloud",
    "amplitude": "Amplitude",
    "servicenow_bug": "Servicenow",
    "servicenow_feature": "Servicenow ",
    "pendo": "Pendo",
    "freshservice_bug": "Freshservice",
    "freshservice_feature": "Freshservice",
    "amazon_s3": "Amazon S3",
    "amazon_redshift": "Amazon Redshift",
    "snowflake": "Snowflake",
    "google_big_query": "Google BigQuery"
  },

  "loading_data_please_wait": "Loading data... Please wait",
  "awaiting_data_from_peripheral_apps": "Awaiting data from the peripheral apps... Please comeback after sometime...",
  "match": "Match",
  "un_match": "Un-match",
  "matched": "Matched",
  "no_match_found": "No Match Found",
  "unique_id": "Unique ID",
  "phone": "Phone",
  "location": "Location",
  "no_data_found": "No data found",
  "close": "Close",
  "zapscale": "Zapscale",
  "zs": "ZS",
  "product_environments": "Product Environments",
  "c1_product_environments": {
    "production": "Production",
    "stage": "Stage",
    "quality_assurance": "QA",
    "development": "Development"
  },
  "update": "Update",
  "cs_handfeel": "CS Gutfeel",
  "cs_handfeel_no_edit_permission_msg": "Aww.. it seems you do not have the permission to update CS Gutfeel Score. Please check with your manager.",
  "customer_type": "Customer Type",
  "domain": "Domain",
  "matchers": "Matchers",
  "manually_matched": "Manually Matched",
  "app_name": "App Name",
  "customer_name": "Customer Name",
  "mobile": "Mobile",
  "choose_orphan_customer_manual_map_msg": {
    "msg_part1": "Unmapped Suspect Customers for manual match with SaaS Customer",
    "msg_part2": "for"
  },
  "suspect_customers": "Suspect Customers",
  "manual_match": "Manual Match",
  "no_suspect_customer_found_for_apptype": "No Suspect Customer found for",
  "green": "Good",
  "yellow": "Fair",
  "red": "Bad",
  "grey": "Inactive",
  "black": "Churn",
  "churn_probability_enum_value": {
    "very_low": "Very Low",
    "low": "Low",
    "mid": "Mid",
    "high": "High",
    "very_high": "Very High",
    "churned": "Churned"
  },
  "churn_probability_enum_color": {
    "very_low": "green",
    "low": "green",
    "mid": "yellow",
    "high": "red",
    "very_high": "red",
    "churned": "black"
  },
  "acv": "ACV",
  "arr": "ARR",
  "type": "Type",
  "zapscore": "Zapscore",
  "user_health_percentage": "User Health (%)",
  "outcome_success": "Outcome Success",
  "peripheral_apps": "Peripheral Apps",
  "upsell_success": "Upsell Success",
  "user_type": "User Type",
  "user_persona": "User Persona",
  "usage_success": "Usage Success",
  "outcome_score": "Outcome Score",
  "user_health_movement": "User Health Movement",
  "peripheral_success": "Peripheral Success",
  "revenue_threat_vs_health": "Revenue Threat vs Health",
  "customer_user_health_trend": "Customer / User Health Trend",
  "churn_and_revenue_trends": "Churn and Revenue Trends",
  "dashboard_table_column_names": {
    "kpi": "KPI",
    "kpi_score": "KPI Score",
    "customer_health": "Customer Health",
    "steady_customers": "Steady Customers",
    "onboarding_customers": "Onboarding Customers",
    "trialing_customers": "Trialing Customers"
  },
  "health_movements": "Health Movements",
  "your_business_kpi_trend": "Your Business KPI Trend",
  "customer_list": "Customer List",
  "mins_customer_details_time": "Mins - per %{time}",
  "pages_customer_details_time": "Pages - per %{time}",
  "features_customer_details_time": "Features - per %{time}",
  "bugs_customer_details_time": "Tickets - per %{time}",
  "per_time": "Per %{time}",
  "customer_segment": "Customer Segment",
  "title": "Title",
  "set_condition": "Set Condition ({type})",
  "and": "And",
  "or": "Or",
  "gt": ">",
  "lt": "<",
  "gte": ">=",
  "lte": "<=",
  "between": "Between",
  "equals": "=",
  "is": "Is",
  "in": "Includes",
  "with": "With",
  "compare": "Compare",
  "value": "Value",
  "start_value": "Start value",
  "end_value": "End value",
  "kpi": {
    "zs": "ZapScore",
    "pvvcs": "No. of Pages Visited Score",
    "stvcs": "Total Time on Product Score",
    "pvps": "% Unique Pages Visited Score",
    "fups": "% Unique Features Used Score",
    "uss": "Usage Success Score",
    "oss": "Outcome Success Score",
    "pss": "Peripheral Success Score",
    "upss": "Upsell Success Score",
    "hfs": "Handfeel Score",
    "bts": "Ticket Tracking Score",
    "bls": "Ticket Log Score",
    "ulpb": "100 - % Users Logging Tickets",
    "p1pb": "100 - % P1 Tickets",
    "brs": "Ticket Resolution Score",
    "frs": "Feature Request Score",
    "frls": "Feature Request Log Score",
    "pfrds": "% of no of feature requests delivered",
    "pas": "Subscription and Payment Score",
    "pfps": "% of full payments on time",
    "ppots": "% of payments on time",
    "pdcs": "% Dues Cleared",
    "ppos": "100 - % of 90+ days of overdue payments",
    "ccs": "Customer Communication Score",
    "cfs": "Communication Frequency Score",
    "dslcwc": "100 - days since last communication with Customer",
    "uurs": "Upsell Readiness Score",
    "puers": "% of User limit entitlement reached",
    "wmglqp": "4 week percent user growth score",
    "uuts": "Upsell Tailwind Score",
    "pdmghs": "% of decision makers in good health",
    "pighs": "% of influencers in good health",
    "nls": "No of Logins Score",
    "f1us": "Feature #1 Use Score",
    "f2us": "Feature #2 Use Score",
    "npvs": "No of Page Visit Score",
    "f3us": "Feature #3 Use Score",
    "f4us": "Feature #4 Use Score",
    "f5us": "Feature #5 Use Score",
    "ttps": "Total Time on Product Score"
  },
  "business_kpi": {
    "heading": {
      "zs": "Business ZapScore",
      "uss": "Business Software Usage Score",
      "oss": "Business Outcome Success Score",
      "pss": "Business Peripheral Apps Score",
      "upss": "Business Upsell Score",
      "hfs": "Business Handfeel Score"
    },
    "definition": {
      "zs": "Top level aggregated KPI for you business (aggregating all User KPIs -> Customer KPIs -> Business Level KPIs). \nThis score tells you if your overall CS discipline is doing good in your business. \nSee the business level KPIs below (these are the ones that aggregate to this KPI).",
      "uss": "Top level aggregated KPI (all User KPIs -> Customer KPIs -> Business Level KPIs). \nThis shows at the business level if all your Customers and Users are using your product in a healthy manner. \nSee the Customer and User level Software Usage KPIs, these are the ones that aggregate to this KPI",
      "oss": "Top level aggregated KPI (all User KPIs -> Customer KPIs -> Business Level KPIs). \nThis shows at the business level if all your Customers and Users are deriving business outcome targets (as set by you) by running various use cases on your product. \nSee the Customer and User level Business Outcome KPIs, these are the ones that aggregate to this KPI",
      "pss": "Top level aggregated KPI (all Customer KPIs -> Business Level KPIs). \nThis shows at the business level if all your Customers are seeing healthy experience in your ticketing, payments, email communications and feature requests. \nSee the Customer level Peripheral KPIs below (these are the ones that aggregate to this KPI)",
      "upss": "Top level aggregated KPI (all Customer KPIs -> Business Level KPIs). \nThis shows at the business level if all your Customers are likely to Upsell. \nSee the Customer level Upsell KPIs below (these are the ones aggregate to this KPI)",
      "hfs": "Top level aggregated KPI (all Customer KPIs -> Business Level KPIs). \nThis shows at the business level, the aggregated sentiment of your CSMs about their gut feel health of their customers. \nSee the Customer level CS Handfeel KPIs below (these are the ones that aggregate to this KPI)",
      "bts": "Overall Business Ticket Tracking percentile Score is defined as an aggregated score of your Customer Ticket tracking score. It tells you, how many of your users are logging Tickets and how many of these tickets are getting resolved. It also keeps a track of all highest severity/ Priority Tickets.",
      "frs": "Business Feature Request Score is defined as an aggregated score of all your Customer Feature Request Score. It tells you, how many of your users are logging features request. Are these requested features being delivered to them? Are these feature requests critical in nature?",
      "pas": "Overall Business Payment Subscription Score is defined as a complete aggregated score of all your Customer Payment Subscription scores. It tells you if your customers paying you. If yes, then are these payments done on time? Are your customers paying you in full or partially?",
      "ccs": "Customer communication Score is defined as an aggregated score of Your Customer email tracking score. This score tells, how regularly you are communicating with all of your customers."
    }
  },
  "customer_kpi": {
    "heading": {
      "zs": "Customer ZapScore",
      "uss": "Usage Success Score",
      "oss": "Outcome Success Score",
      "pss": "Peripheral Success Score",
      "upss": "Upsell Success Score",
      "hfs": "CS Handfeel Score"
    },
    "definition": {
      "zs": "Per Customer level aggregated KPI. \nThis score tells you if any Customer is Good / OK / Bad in health. \nSee the Customer Level KPIs below - Usage Success, Outcome Success, Peripheral Success, Upsell Success and CS Handfeel (these are the ones that aggregate to this KPI).",
      "uss": "This score aggregates pages visited, time spent, features used and product coverage achieved by all the users of this customer. \nHigh score shows the overall software usage in your business is good.",
      "oss": "Are your customers successfully using your product use-cases repeatably, consistently and with ease? \nThis KPI tells you if your customer is deriving business value from using your product. \nA high score shows the customer is successfully deriving business value  – good for you. \nThis is aggregated from all of the user scores for this KPI for this customer.",
      "pss": "This score aggregates the experience of this customer by aggregating the KPIs of Ticketing, Payments, Feature Requests and Email Communication. \nHigh score shows your customer's experience is good.",
      "upss": "Is your customer ready for an upsell? If yes how easy would be upsell motion be? \nThis score shows you if your customer is nearing the subscription entitlement limit, what is their user / usage growth rate and what kind of support will you get from the users when you do move the upsell motion. \nHigh score is good for you.",
      "hfs": "What does your CS Team feel about this customer? \nA high score is good for you.",
      "bts": "Your customers are logging bugs, paying for the software subscription, writing emails and requesting features from you. All these are captured in various peripheral apps. This score aggregates all of that tell you if your customers are seeing you in good light or not. High score is good for you.",
      "frs": "Your customers would want more features and would request the same. Are you seeing very high volume of feature request with high consistency? Are you delivering the features? Are your feature requests critical in nature? This score aggregates all these facets into one score.",
      "pas": "Are your customers paying you on time? Are they paying you full or partial? Are the payments overdue? Are they paying you monthly? This score aggregates all your customer payments and gives you one score to track your customer payment health.",
      "ccs": "Staying in communication with your customer is important. This score tells how good your communication is with your customer."
    }
  },
  "user_kpi": {
    "heading": {
      "zs": "User ZapScore",
      "uss": "Usage Success Score",
      "oss": "Outcome Success Score"
    },
    "definition": {
      "zs": "Per user level KPI. \nYour users are using your SaaS product and deriving product use-case  driven business outcomes. This score aggregates all of that and shows you if your user's health is Good / Ok / Bad. \nHigh score shows your user is healthy.",
      "uss": "Are your users using all of the software pages or just a few? \nThis KPI tells you what percentage of total pages your users are using. \nA high score shows your users cover most of the product pages – good for you.",
      "oss": "Are your users successfully using your product use-cases repeatably, consistently and with ease? \nThis KPI tells you if your user is deriving business value from using your product. \nA high score shows the user is successfully deriving business value  – good for you."
    }
  },
  "your_customer_since": "Your customer since: ",
  "data_available_from": "Data available from: ",
  "kpi_off_warning_msg": "Oops..!! It seems like %{kpiname} is turned off. Turn it on from KPI settings to see the health scores",
  "date_selection": "Date Selection",
  "next_date_error": "Cannot select future date",
  "previous_date_error": "Cannot select past date",
  "customer_kpi_trend": "Customer KPI Trend",
  "user_kpi_trend": "User KPI Trend",
  "zapscale_message": {
    "added_on": "%{count} Added on %{dateString}",
    "added_by_date": "%{addedToday} Added on %{dateString}",
    "added_last_week": "%{addedLastWeek} Added last week",
    "added_last_month": "%{addedLastMonth} Added last month",
    "added_last_quarter": "%{addedLastQuarter} Added last quarter",
    "added_last_year": "%{addedLastYear} Added last year"
  },
  "user_since": "User since: ",
  "net_retention_more_details": "YoY net retention",
  "yoy_net_dollar_retention_more_details": "YoY net dollar retention",
  "yoy_churn_more_details": "YoY churn",
  "assigned_to_param": "Assigned to: %{assigned_to}",
  "health_param": "Health: %{health}",
  "acv_param": "Acv: %{acv}",
  "arr_param": "Arr: %{arr}",
  "cp": "Churn Probability: %{cp}",
  "type_param": "Type: %{type}",
  "name_param": "Name: %{name}",
  "renewal_in_param": "Renewal in: %{renewal_in} days",
  "customer_segment_param": "Customer Segment: %{customer_segment}",
  "date_as_on": "Date as on %{date}",
  "is_starred_c2": "Star Customer",
  "is_starred_u2": "Star User",
  "kpi_caption": {
    "stvcs": "Usage scores are computed on a daily basis on daily data",
    "pvvcs": "Usage scores are computed on a daily basis on daily data",
    "pvps": "Usage scores are computed on a daily basis on daily data",
    "fups": "Usage scores are computed on a daily basis on daily data",
    "bts": "Ticket scores are computed on a daily basis based on rolling data of last 30 days",
    "bls": "Ticket scores are computed on a daily basis based on rolling data of last 30 days",
    "ulpb": "Ticket scores are computed on a daily basis based on rolling data of last 30 days",
    "p1pb": "Ticket scores are computed on a daily basis based on rolling data of last 30 days",
    "brs": "Ticket scores are computed on a daily basis based on rolling data of last 30 days",
    "frs": "Feature Request scores are computed on a daily basis based on rolling data of last 90 days",
    "frls": "Feature Request scores are computed on a daily basis based on rolling data of last 90 days",
    "pfrds": "Feature Request scores are computed on a daily basis based on rolling data of last 90 days",
    "pas": "Subscription and Payment scores are computed on a daily basis based on rolling data of last 365 days",
    "pfps": "Subscription and Payment scores are computed on a daily basis based on rolling data of last 365 days",
    "ppots": "Subscription and Payment scores are computed on a daily basis based on rolling data of last 365 days",
    "pdcs": "Subscription and Payment scores are computed on a daily basis based on rolling data of last 365 days",
    "ppos": "Subscription and Payment scores are computed on a daily basis based on rolling data of last 365 days",
    "ccs": "Communication scores are computed on a daily basis based on rolling data of last 30 days",
    "cfs": "Communication scores are computed on a daily basis based on rolling data of last 30 days",
    "dslcwc": "Communication scores are computed on a daily basis based on rolling data of last 30 days",
    "uurs": "Upsell scores are computed on a daily basis based on rolling data of last 90 days",
    "puers": "Upsell scores are computed on a daily basis based on rolling data of last 90 days",
    "wmglqp": "Upsell scores are computed on a daily basis based on rolling data of last 90 days",
    "uuts": "Upsell scores are computed on a daily basis based on rolling data of last 90 days",
    "pdmghs": "Upsell scores are computed on a daily basis based on rolling data of last 90 days",
    "pighs": "Upsell scores are computed on a daily basis based on rolling data of last 90 days",
    "intercom_cfs": "Intercom communication scores are computed on a daily basis based on rolling data of last 30 days",
    "intercom_dslcwc": "Intercom communication scores are computed on a daily basis based on rolling data of last 30 days",
    "slack_cfs": "Slack communication scores are computed on a daily basis based on rolling data of last 30 days",
    "slack_dslcwc": "Slack communication scores are computed on a daily basis based on rolling data of last 30 days"
  },
  "kpi_tray_column": {
    "time": "Time",
    "kpi_score": "KPI percentile (score)",
    "score": "Percentile (score)",
    "total_no_of_pages": "Total no. of pages",
    "total_no_of_page_visit": "Total no. of page visits",
    "avg_page_visit_per_user": "Avg page visit per user",
    "avg_time_spent_per_user": "Avg time spent per user",
    "total_no_of_unique_pages": "Total no. of unique pages",
    "total_no_of_unique_page_visited": "Total no. of unique \npage visited",
    "total_no_of_features": "Total no. of features",
    "total_no_of_unique_feature_used": "Total no. of unique \nfeature used",
    "total_no_of_invoices": "Total no. of invoices",
    "no_of_feature_logged": "No. of FRs logged",
    "no_of_feature_resolved": "No. of FRs resolved",
    "no_of_feature_used": "No. of FRs used",
    "no_of_unique_features_used": "No. of unique features used",
    "no_of_feature_request_delivered": "No. of FRs delivered",
    "no_of_feature_requests": "No. of FRs",
    "no_of_90__days_overdue": "No. of 90+ days \noverdue payments",
    "percentage_of_90__days_overdue": "% of 90+ days not \noverdue payments (score)",
    "days_since_last_email": "Days since last emails from Customer",
    "no_of_tickets_logged": "No. of tickets logged",
    "no_of_unresolved_tickets": "No. of unresolved tickets",
    "no_of_hours_usage": "No. of hours of usage",
    "no_of_minutes_of_usage": "No. of minutes of usage",
    "no_of_due_payments": "No. of due payments",
    "no_of_overdue_payments": "No. of overdue payments",
    "no_of_full_payments": "No. of full payments",
    "percentage_of_full_payments": "% of full payments (score)",
    "no_of_email_last_30_days": "No. of emails in last 30 days",
    "no_of_resolved_tickets": "No. of resolved tickets",
    "no_of_logged_tickets": "No. of logged tickets",
    "percentage_tickets_resolved": "% tickets resolved (score)",
    "no_of_user_logging_tickets": "No. of users logging tickets",
    "percentage_of_users_log_tickets": "% of users logging tickets",
    "no_of_users": "No. of users",
    "100_percentage_of_users_log_tickets": "100 - % of users logging \ntickets (score)",
    "no_of_time_payments": "No. of on time payments",
    "percentage_of_time_payments": "% of on time payments (score)",
    "no_of_p1_tickets": "No. of P1 tickets",
    "percentage_p1_tickets": "% P1 tickets",
    "100_percentage_p1_tickets": "100 - % P1 tickets (score)",
    "entitlement_limit": "Entitlement Limit",
    "used_so_far": "Used so far",
    "user_usage_4_weeks_back": "User / Usage 4 weeks back",
    "user_usage_now": "User / Usage NOW",
    "4_week_percent_user_growth_score": "4 week percent user \ngrowth percentile (score)",
    "no_of_decision_makers": "No. of Decision Makers",
    "no_in_good_health": "No. in good health",
    "percent_decision_makers_in_good_health ": "% of decision makers \nin good health",
    "no_of_influencers": "No. of Influencers",
    "percent_influencer_in_good_health ": "% of influencers in good health",
    "90_day_invoice_value": "365 day Invoice Value",
    "90_day_money_paid": "365 day money paid",
    "percent_dues_cleared": "% Dues Cleared (score)",
    "90_days_no_of_invoices": "365 days No of Invoices",
    "90_days_no_of_invoices_paid_full_on_time": "365 days No of Invoices \npaid full on time",
    "90_days_%_full_payment_on_time": "365 days % full payment \non-time (score)",
    "no_of_overdue_invoices": "No of overdue invoices",
    "no_of_overdue_invoices_90+_days": "No of overdue \ninvoices 90+ days",
    "total_overdues": "Total Overdues",
    "total_overdues_90+_days": "Total Overdues 90+ days",
    "100_%_90+_days_overdue_invoices": "100 - % 90+ days \noverdue Invoices (score)",
    "no_of_intercom_comm_last_30_days": "No. of intercom communications in last 30 days",
    "days_since_last_intercom_comm": "Days since last intercom communications with Customer",
    "no_of_slack_comm_last_30_days": "No. of slack communications in last 30 days",
    "days_since_last_slack_comm": "Days since last slack communications with Customer"
  },
  "kpi_popup_list": {
    "time": "Time",
    "no_of_feature_logged": "No. of FRs logged",
    "no_of_feature_used": "No. of FRs used",
    "kpi_score": "KPI Score",
    "frls_message": "Feature Request scores are computed on a daily basis based on rolling data of last 90 days",
    "data_as_on": "Data as on %{date}",
    "no_of_due_payment": "No. of due payments",
    "no_of_90__days_overdue": "No. of 90+ days overdue payments",
    "percentage_of_90__days_overdue": "% of 90+ days overdue payments",
    "ppos_message": "Subscription and Payment scores are computed on a daily basis based on rolling data of last 90 days",
    "days_since_last_email": "Days since last emails from Customer",
    "score": "Score",
    "dslcwc_message": "Communication scores are computed on a daily basis based on rolling data of last 30 days",
    "no_of_tickets_logged": "No. of tickets logged",
    "no_of_hours_usage": "No. of hours of usage",
    "bls_message": "Ticket scores are computed on a daily basis based on rolling data of last 30 days",
    "no_of_full_payments": "No of full payments",
    "percentage_of_full_payments": "% of full payments",
    "pfps_message": "Subscription and Payment scores are computed on a daily basis based on rolling data of last 90 days",
    "no_of_email_last_30_days": "No of emails in last 30 days",
    "cfs_message": "Communication scores are computed on a daily basis based on rolling data of last 30 days",
    "no_of_resolved_tickets": "No of resolved tickets",
    "no_of_logged_tickets": "No of logged tickets",
    "percentage_tickets_resolved": "% tickets resolved",
    "brs_message": "Ticket scores are computed on a daily basis based on rolling data of last 30 days",
    "no_of_user_logging_tickets": "No of users logging tickets",
    "percentage_of_users_log_tickets": "% of users logging tickets",
    "ulpb_message": "Ticket scores are computed on a daily basis based on rolling data of last 30 days",
    "no_of_due_payments": "No. of due payments",
    "no_of_time_payments": "No of on time payments",
    "percentage_of_time_payments": "% of on time payments",
    "ppots_message": "Subscription and Payment scores are computed on a daily basis based on rolling data of last 90 days",
    "no_of_p1_tickets": "No of P1 tickets",
    "percentage_p1_tickets": "% p1 tickets",
    "p1pb_message": "Ticket scores are computed on a daily basis based on rolling data of last 30 days"
  },
  "role_param": "Role: %{role}",
  "customer_type_param": "Customer Type: %{customer_type}",
  "user_role": "User Role",
  "apply_filter": "Apply Filter",
  "c1_business_areas": {
    "financial": "Financial",
    "manufacturing": "Manufacturing",
    "hr": "HR-Services",
    "logistics": "Logistics",
    "ad_tech": "Ad-tech",
    "agri_tech": "Agri-tech",
    "telecom": "Telecom",
    "marketing": "Marketing",
    "supply_chain": "Supply-Chain",
    "healthcare": "Healthcare",
    "govt": "Govt",
    "real_estate": "Real-Estate",
    "insurance": "Insurance",
    "transportation": "Transportation",
    "agriculture": "Agriculture",
    "defence": "Defense",
    "education": "Education",
    "energy": "Energy",
    "entertainment": "Entertainment",
    "hospitality": "Hospitality",
    "media": "Media",
    "tech_tool": "Tech-Tool",
    "others": "Others"
  },
  "c1_business_info_ranges": {
    "0_25": "0 - 25",
    "25_50": "25 - 50",
    "50_100": "50 - 100",
    "100_300": "100 - 300",
    "300_500": "300 - 500",
    "500_1000": "500 - 1000",
    "1000_5000": "1000 - 5000",
    "5000_10000": "5000 - 10000",
    "10000_above": "10000 and above"
  },
  "business_meta_data_greeter": "Hey there, we would really like to know you better. Come on, tell us a bit about your business.",
  "what_is_company_name": "What is your Company Name?",
  "how_many_customers": "How many Customers do you have?",
  "what_is_business_domain": "What is your business type?",
  "how_many_users": "How many users use your SaaS product?",
  "how_many_folks": "No of employees in your company?",
  "where_business_based": "Where is the HQ of your business?",
  "customer_param": "Customer: %{customer}",
  "csm_param": "CSM: %{csm}",
  "account_manager_param": "Account manager: %{account_manager}",
  "sales_person_param": "Sales person: %{sales_person}",
  "creator_param": "Creator: %{creator}",
  "priority_param": "Priority: %{priority}",
  "status_param": "Status: %{status}",
  "customer_upload_success": "Customers uploaded successfully",
  "user_upload_success": "Users uploaded successfully",
  "customers": "Customers",
  "upload_new_csv": "Upload New CSV",
  "download_csv_format": "Download CSV Format",
  "download_existing_customers": "Download Existing Customers",
  "download_existing_users": "Download Existing Users",
  "overdue_tasks": "Overdue Tasks",
  "gutfeel_csv_upload_success": "Gutfeel CSV uploaded successfully",
  "gutfeel_csv_upload_fail": "Failed to update gutfeel for any customer",

  "file_name": "Customer Information",
  "user_file_name": "User Information",
  "file_name_with_GutfeelScore": "Customer Information With Gutfeel Score",
  "zapbook_action_list": "Zapbook Actions List",
  "zapbook_task_list": "Zapbook Tasks List",

  "no_data": "No Data",
  "product_name": "Product Name",
  "suspect_products": "Suspect Products",
  "plan_not_found": "Plan Not Found",
  "churn_possibility": "Churn Possibility",
  "customer_zapscore": "Customer Zapscore",
  "user_zapscore": "User Zapscore",
  "upsell_probability": "Upsell Probability",
  "annual_recurring_revenue": "Annual Recurring Revenue",
  "user_type_param": "User Type: %{user_type}",
  "user_persona_param": "User Persona: %{user_persona}",
  "user_name_param": "User Name: %{user_name}",
  "sankey": {
    "inactive_dec": "Inactive",
    "inactive_jan": "Inactive",
    "churn_jan": "Churn",
    "downgrades_dec": "Bad",
    "downgrades_jan": "Bad",
    "steady_dec": "Fair",
    "steady_jan": "Fair",
    "upgrades_dec": "Good",
    "upgrades_jan": "Good"
  },
  "confirm": "Confirm",
  "delete_confirmation": "Are you sure you want to delete %{name}?",
  "server_error": {
    "INVALID_C2": "Invalid customer"
  },
  "kpi_list": "KPI list",
  "customer_tab": {
    "overview": "Overview",
    "health_kpi": "Health KPI's",
    "all_data": "All Data",
    "tasks": "Tasks",
    "messages": "Messages",
    "actions": "Actions",
    "insights": "Insights",
    "success_plans": "Success Plans",
    "documents": "Docs",
    "upload_document": "Upload Your Document",
    "notes": "Notes",
    "upsell_deals": "Upsell Deals",
    "renewal_deals": "Renewal Deals"
  },
  "customer_health_tab": {
    "usage_success": "Usage Health",
    "outcome_success": "Outcome Health",
    "peripheral_success": "Ticketing/Billing/Emails \nHealth",
    "upsell_success": "Upsell Health",
    "cs_handfeel": "CS Gutfeel"
  },
  "customer_all_data_tab": {
    "ticketing": "Ticketing",
    "feature_request_data": "Feature Request",
    "payment_data": "Subscription & Billing",
    "email_data": "Email",
    "product_feature_use": "Product Feature Use",
    "communication_systems_data": "Communication Systems",
    "api_data": "API Data"
  },
  "user_tab": {
    "overview": "Overview",
    "health_kpi": "Health KPI's",
    "all_data": "All Data",
    "tasks": "Tasks",
    "messages": "Messages",
    "actions": "Actions",
    "insights": "Insights"
  },
  "user_health_tab": {
    "usage_success": "Usage Health",
    "outcome_success": "Outcome Health"
  },
  "user_data_tab": {
    "feature_data": "Product Feature Use"
  },
  "zapbook_tab": {
    "overview": "Overview",
    "tasks": "Tasks",
    "notes": "Notes",
    "messages": "Messages",
    "actions": "Actions",
    "insights": "Insights",
    "performance": "Performance",
    "success_plan": "Success Plan",
    "upsell_deals": "Upsell Deals",
    "renewal_deals": "Renewal Deals"
  },
  "total_playbooks": "Total Playbooks",
  "active_playbooks": "Active Playbooks",
  "playbooks": "Playbooks",
  "playbook_kpi_off_msg": "Linked KPI is turned Off",
  "kpi_weight": "KPI Weight: %{weight}",
  "customer_cohort_average": "Customer cohort average",
  "all_customer_average": "All customer average",
  "select_year": "Select Year",
  "select_week": "Select Week",
  "select_month": "Select Month",
  "select_quarter": "Select Quarter",
  "please_wait": "Please wait...",
  "before_date": "Before %{date}",
  "created_date": "Created at: %{date}",
  "aggregation_type": {
    "day": "day",
    "week": "week",
    "month": "month",
    "quarter": "quarter",
    "year": "year"
  },
  "commentary": {
    "c2_health_score": {
      "zs": "All the scores combined in this %{aggregation_type} gives %{c2_name_percentile} percentile score among all customers of %{c2_type_name} type."
    }
  },
  "peripheral_app_type_names": {
    "crm": "CRM",
    "ticketing": "Ticketing",
    "email_system": "Email System",
    "feature_requests": "Feature Requests",
    "payment_system": "Subscription & Billing System"
  },
  "peripheral_email_domain": "csdev.zapscale.io",
  "peripheral_email_placeholder": "Enter company email",
  "email_integration_type": {
    "cc_email_in_every_communication": "I will cc this email in every communication",
    "forward_all_communications_to_this_email": "I will forward all communications to this email",
    "add_email_in_my_support_email_address_group": "I will add this email in my support email address group"
  },
  "email_already_in_use": "Email already in use",
  "select_location": "Select Location",
  "select_plan": "Select Plan",
  "signed_out_from_another_tab": "You have signed out from another tab",

  "invite_user_tabs": {
    "overview": "Overview",
    "zapscale_users": "ZapScale Users",
    "cs_team": "CS Team",
    "tech_and_product": "Tech & Product",
    "sales_and_marketing": "Sales & Marketing",
    "finance": "Finance",
    "training_and_support": "Training & Support",
    "others": "Others"
  },

  "invite_user_greeter": {
    "part1": "Hey there, we believe Customer Success is everyone's concern. So, we want you to invite your entire CS team to ZapScale. Best part,",
    "part2": "it is free to invite as many of your folks..."
  },
  "invite_user_options": {
    "edit": "Edit user details",
    "delete": "Delete user",
    "resend": "Resend invitation"
  },
  "accept_invitation": {
    "reset_password_message": "Please check your email inbox for a link to set password."
  },
  "accept_help_request": {
    "reset_password_message": "Please check your email inbox for a link to set password."
  },
  "invite_user": "Invite User",
  "admin_rights": "Admin Rights",
  "Task_overdue": "Task overdue",

  "invite_user_delete_confirmation": {
    "title": "Delete Invited User",
    "body": "Do you want to delete %{userName}?",
    "delete_user_confirm": "Yes, I Confirm",
    "delete_user_cancel": "Cancel"
  },
  "no_name": "No Name",
  "more_details": "More Details",
  "coming_soon": "Coming Soon",
  "ticket_log": {
    "historical_data": "Historical Data",
    "ticket_id": "Ticket ID",
    "ticket_data": "Ticket data",
    "feature_data": "Feature data",
    "product": "Product",
    "ticket_name": "Ticket Name",
    "priority": "Priority",
    "status": "Status",
    "created_at": "Created Date",
    "updated_at": "Updated Date",
    "customer_name": "Customer Name",
    "no_of_tickets_logged": "No. of tickets logged in last %{dayCount} days:",
    "no_of_resolved_tickets": "No. of resolved tickets in last %{dayCount} days:",
    "no_of_feature_requests": "No. of feature requests in last %{dayCount} days:",
    "no_of_feature_resolved": "No. of FRs resolved in last %{dayCount} days:",
    "ageing_days": "Ageing Days"
  },
  "feature_log": {
    "feature_id": "Feature ID",
    "feature_name": "Feature Name",
    "product": "Product",
    "priority": "Priority",
    "status": "Status",
    "created_at": "Created Date",
    "updated_at": "Updated Date",
    "ageing_days": "Ageing Days"
  },
  "payment_log": {
    "historical_data": "Historical Data",
    "payment_data": "Subscription & Billing data",
    "product": "Product",
    "invoice_no": "Invoice No",
    "subscription_name": "Subscription Name",
    "invoice_date": "Invoice Date",
    "due_amount": "Due Amount",
    "invoiced_amount": "Invoiced Amount",
    "due_date": "Due Date",
    "paid_amount": "Paid Amount",
    "total_paid_amount": "Total Amount Paid",
    "payment_date": "Payment Date",
    "remaining_amount": "Remaining Amount",
    "total_due_amount": "Total Amount Due",
    "customer_name": "Customer Name",
    "days_no_of_invoices": "%{dayCount} days No of Invoices:",
    "days_no_of_invoices_paid_full_on_time": "%{dayCount} days No of Invoices paid full on time:",
    "ageing_days": "Ageing Days",
    "subscription": "Subscription",
    "invoice_status": "Invoice Status",
    "day": "days | day | days"
  },
  "email_log": {
    "email_data": "Email data",
    "historical_data": "Historical Data",
    "customer": "Customer",
    "date": "Date",
    "from": "From",
    "to/cc": "To/CC",
    "subject": "Subject",
    "body": "Body",
    "importance": "Importance",
    "no_of_email_in_last_days": "No of Emails in last %{dayCount} days:",
    "days_since_last_email": "Days since last email:",
    "email": "Email",
    "content": "Content"
  },
  "api_log": {
    "api_data": "API Data",
    "date": "Date",
    "key_name": "Key Name",
    "value": "Value",
    "type": "Type"
  },
  "zapbook_v2": {
    "zapbook": "Zapbook",
    "task": "Task | Task | Tasks",
    "messages": "Message | Message | Messages",
    "action": "Action | Action | Actions",
    "success_plan_actions": "Success Plan Actions",
    "your_performance": "Your Performance",
    "your_churn_numbers": "Your Churn Numbers",
    "your_grr_numbers": "Your GRR Numbers",
    "your_nrr_numbers": "Your NRR Numbers"
  },
  "zapbook_data_card": {
    "customers": "Customers",
    "annual_revenue": "Annual Revenue",
    "users": "Users",
    "possible_upsells": "Possible Upsells",
    "churn_threats": "Churn Threats",
    "possible_churn_revenue": "Possible Churn Revenue",
    "upcoming_renewals_less_than_30_days": "Upcoming Renewals < 30 days",
    "amount_under_upcoming_renewals": "$ Under upcoming renewal"
  },
  "zapbook_v2_message": {
    "message": "Message"
  },
  "zapbook_v2_action": {
    "creator": "Creator: %{creator}",
    "time_taken": "Time: %{time}",
    "type": "Type: %{type}"
  },
  "performance_header": {
    "performance": "Performance",
    "close_compare": "Close compare"
  },
  "c2_feature_data": {
    "current_avg_day": "This week avg",
    "current_avg_week": "This month avg",
    "current_avg_month": "This quarter avg",
    "current_avg_quarter": "This year avg",
    "last_avg_day": "Last week avg",
    "last_avg_week": "Last month avg",
    "last_avg_month": "Last quarter avg",
    "last_avg_quarter": "Last year avg",
    "movement": "% Movement"
  },
  "csv_upload": {
    "size_error": "CSV file size can not be more than 200 KB",
    "format_error": "Only CSV files are allowed"
  },
  "saas_app_greeter": {
    "part1": "Your SaaS app is an important place to get information about your Customers' and Users' software usage and business outcome data. ZapScale comes with",
    "part2": "16 KPIs and 8 playbooks pre-loaded to measure these",
    "part3": ". Complete the steps and you will be all set."
  },
  "customer_and_user_greeter": "Now, complete your Customers' and Users' settings. Map your customers and products. Assign CSMs / Account Managers to your customers. Complete these and you will be all set to start using ZapScale.",
  "upsell": {
    "high": "High",
    "mid": "Mid",
    "low": "Low"
  },
  "page_table_column_name": {
    "name": "Name",
    "url": "URL",
    "created_at": "Created At",
    "updated_at": "Updated At"
  },
  "sitemap_fetch_failure": "Failed to fetch sitemap",
  "fetch_product_failure": "Failed to fetch product",
  "pages": "Pages",
  "edit_page": "Edit Page",
  "create_new_page": "Create New Page",
  "page_response_msg": {
    "create_page_success": "Page created successfully",
    "create_page_failure": "Failed to create page",
    "update_page_success": "Page updated successfully",
    "update_page_failure": "Failed to update page"
  },
  "page_name": "Page Name",
  "page_url": "Page URL",
  "site_map_xml_format": "Sitemap (XML format)",
  "paste_site_map_here": "Paste your sitemap here",
  "customer_type_change_confirmation": {
    "title": "Customer Type Change Confirmation",
    "body1": "No Customer's Type | Type of 1 Customer | Type of {count} Customers",
    "body2": "will be changed by this operation. Do you want to proceed with the change? Please confirm",
    "type_change_confirm": "Yes, I Confirm",
    "type_change_cancel": "Cancel"
  },
  "c2_type_update_success": "Customer type updated successfully",
  "c2_type_update_failure": "Failed to update customer type",
  "duration_in_days": "Duration in days",
  "set_customer_type": {
    "yes_we_offer": "Yes, we offer",
    "trail": "Trial",
    "onboarding": "Onboarding",
    "steady": "Steady",
    "to_our_customers": "to our Customers",
    "after_this_a_customer_becomes_a": "After this the Customer becomes a",
    "paying_customer": "paying customer"
  },
  "churn_and_revenue_trend_graph_tooltip": {
    "total_acv": "Total ARR: %{value}",
    "new_acv": "New ARR: %{value}",
    "churned_acv": "Churned ARR: %{value}",
    "total_customers": "Total Customers: %{value}",
    "new_customers": "New Customers: %{value}",
    "churned_customers": "Churned Customers: %{value}"
  },
  "colors": {
    "red": "RED",
    "yellow": "YELLOW",
    "green": "GREEN",
    "grey": "GREY",
    "black": "BLACK"
  },

  "playbook_display_group_row": {
    "name": {
      "business_health_zapscore": "Your Business Health (ZapScore) Playbooks",
      "business_third_party_app_health": "Your Business Software Usage + 3rd Party App Health Playbooks",
      "customer_overall_health_zapscore": "Customer Overall Health (ZapScore) Playbooks",
      "inactive_customer_playbooks": "Inactive Customer Playbooks",
      "customer_software_usage_health": "Customer Software Usage Health Playbooks",
      "customer_business_outcome_health": "Customer Business Outcome Health Playbooks",
      "customer_ticketing_health": "Customer Ticketing Health Playbooks",
      "customer_communication_health": "Customer Communication Health Playbooks",
      "customer_payment_and_subscription_health": "Customer Subscription & Billing Health Playbooks",
      "customer_feature_request_health": "Customer Feature Request Health Playbooks",
      "customer_upsell_health": "Customer Upsell Health Playbooks",
      "customer_saas_app_utilization": "Customer SaaS App Utilization Playbooks",
      "customer_renewal": "Customer Renewal Playbooks",
      "customer_qbr": "Customer QBR Playbooks",
      "customer_churn_risk": "Customer Churn Risk Playbooks",
      "user_overall_health_zapscore": "User Overall Health (ZapScore) Playbooks"
    },
    "description": {
      "business_health_zapscore": "What steps should be followed if you find that your Overall Business ZapScore is RED ?\nOverall Business ZapScore is defined as a complete aggregated score of all your Customer Success endeavors. It includes the Usage Success score across all your users, the Outcomes achieved by your customers, email communication, tickets tracked, payments tracked, feature request tracked, upsell readiness score, and the gut feel of your CS Team.",
      "business_third_party_app_health": "What steps should be followed if you find that your Overall Business Software Usage + 3rd Party App Health score is RED?\nOverall Business Software Usage + 3rd Party App Health score is defined as an aggregated score across all your customers for usage, outcomes achieved, email communication, tickets tracked, payments tracked, feature requests tracked and upsell Score.",
      "customer_overall_health_zapscore": "What steps should be followed if you find that your Customer ZapScore is RED?\nCustomer ZapScore is defined as a complete aggregated score for a specific customer with Usage Success of the users, the Outcomes achieved by the customer, Tickets tracked, email communication, payments tracked, feature requests tracked, upsell readiness score, and the gut feet score of your CS Team.",
      "inactive_customer_playbooks": "What steps should be followed if you find that your Customer ZapScore is INACTIVE? \nCustomer ZapScore is INACTIVE denotes that the users of the specific customer are not using the product at all.",
      "customer_software_usage_health": "What steps should be followed if we find one of your customers has poor software usage ? \nSoftware usage is defined for a customer across all the users showing enough volume, consistency and time spent in product sessions. It tells you if users are showing high coverage of your software pages and features or not.",
      "customer_business_outcome_health": "What steps should be followed if we find one of your customers has poor outcomes score?\nOutcomes Success is defined as the goals the customers wish to achieve from your product use cases and key business results.",
      "customer_ticketing_health": "What steps should be followed if we find one of your customers has a poor Ticket Tracking Score?\nTicket Tracking Score is defined as the combination of volume of the tickets logged, the severities of those tickets, the resolution status and the volume of users logging the tickets in a 30 day rolling interval.",
      "customer_communication_health": "What steps should be followed if we find one of your customers has a poor Communication Score?\nCustomer Email Tracking Score shows the regularity of the customer in communicating with your team rolling over last 30 days.",
      "customer_payment_and_subscription_health": "What steps should be followed if we find one of your customers has a poor Subscription & Billing Score for?\nThe payments subscription Score is a combination of how many payments were made full, how many payments came on-time and how many payments are 90+ days overdues over a 90 day rolling period.",
      "customer_feature_request_health": "What steps should be followed if we find one of your Customer Feature Request Score is RED?\nFeature Request Score is is a combination of the volume of Features requested and the rate of resolution of those features over a 90 day rolling period.",
      "customer_upsell_health": "What steps should be taken when a customer is ready to Upsell? \n Customer Upsell Score tells you if your customer is ready for a upsell communication. It tells you if your customers are nearing their user/usage license limit, the rate of growth of license utilization and the health of the influencers and decision makers who would support the upsell motion.",
      "customer_saas_app_utilization": "What steps should be followed if a customer has utilized the maximum limit of the entitled users/usage?",
      "customer_renewal": "What steps should be followed if a customer renewal date is Close by?",
      "customer_qbr": "What steps should be followed if a customer QBR is Close by?",
      "customer_churn_risk": "What steps should be followed if a customer is identified to be at a major churn risk?",
      "user_overall_health_zapscore": "What steps should be followed if you find that your User ZapScore is RED?\nUser ZapScore is defined as a complete aggregated score of the Usage Success of the users and the Outcomes achieved by the Users."
    }
  },

  "playbook": {
    "set_audience": "Set Audience",
    "audience_for": "Audience for",
    "customer": "Customer",
    "user": "User",
    "set_type": "Set Type",
    "type": "Type",
    "remedial_step": "Remedial Steps",
    "message_texts": "Message Texts",
    "add_text": "Add Text",
    "add_step": "Add Step",
    "email_texts": "Email Texts",
    "email_text": "Email Text",
    "internal_email": "Internal Email",
    "customer_email": "Customer Email",
    "type_options": {
      "business": "Business",
      "customer": "Customer",
      "user": "User"
    },
    "set_condition": "Set condition",
    "duration": "Duration",
    "duration_type": "Duration Type",
    "duration_types": {
      "day": "Day",
      "week": "Week",
      "month": "Month",
      "quarter": "Quarter",
      "year": "Year"
    },
    "color": "Color",
    "user_type": "User Type",
    "user_role": "User Role",
    "audience": {
      "acv": "ACV",
      "arr": "ARR",
      "days_to_renewal": "Days to renewal",
      "customer_day_no": "Customer day no.",
      "zapscore": "Zapscore",
      "customer_segment": "Customer segment"
    },
    "condition": {
      "health": "Health",
      "percentile": "Percentile"
    }
  },

  "playbook_trigger_condition": {
    "condition": "Condition",
    "condition_for": "Condition for",
    "kpi_condition": {
      "health": "Health",
      "percentile_change_from_last": "Percentile Change From Last",
      "score": "Score",
      "percentile": "Percentile"
    },
    "qbr_for": "Qbr for",
    "qbr_day_label": "Days",
    "qbr_before_label": "Before days",
    "qbr_after_label": "After days",
    "qbr_type": {
      "before_due_date": "Before due date",
      "after_due_date": "After due date"
    },
    "churn_probability": "Churn Probability",
    "condition_with": {
      "kpi": "Kpi",
      "churn": "Churn",
      "renewal": "Renewal",
      "qbr": "Qbr",
      "stage_change": "Stage change",
      "time_bound": "Time bound",
      "custom_field": "Custom Field"
    }
  },

  "common": {
    "edit": "Edit",
    "cancel": "Cancel",
    "save": "Save",
    "ok": "Ok",
    "download": "Download"
  },

  "saas_app_product_list": {
    "header": "Products",
    "add_product": "Add Product",
    "empty_header": "You don't have any products",
    "empty_description": "Get started by adding your product"
  },

  "saas_app_product_form": {
    "add_new": "Add New Product",
    "edit": "Edit Product",
    "create_success_message": "Product added",
    "update_success_message": "Product updated"
  },

  "saas_app_domain_list": {
    "header": "Domains",
    "add_domain": "Add Domain",
    "empty_header": "You don't have any domains",
    "empty_description": "Get started by adding your domain"
  },

  "saas_app_domain_form": {
    "add_new": "Add New Domain",
    "edit": "Edit Domain",
    "create_success_message": "Domain added",
    "update_success_message": "Domain updated"
  },

  "saas_app_event_list": {
    "header": "Your SaaS App Events",
    "add_product": "Add Product",
    "empty_product_header": "You don't have any products",
    "empty_product_description": "Events cannot be created without adding your product first",
    "empty_header": "There are no events",
    "empty_description": "Get started by creating your first event"
  },
  "customers_vue": {
    "ticket": "Ticket",
    "feature": "Feature",
    "billing": "Billing",
    "email": "Email",
    "filter_by": "Filtered By",
    "clear": "Clear",
    "filter": "Filter",
    "shared": "Shared",
    "column_headers": {
      "customers": "Customers",
      "total_users": "Total users",
      "zs": "ZapScore",
      "user_health_percentage": "User Health (%)",
      "uss": "Product Usage\nPercentile",
      "apus": "Average Product Usage Score",
      "oss": "Business Outcomes\nPercentile",
      "bts": "Ticket score\npercentile",
      "ats": "Average Ticketing Score",
      "frs": "Feature score\npercentile",
      "afrs": " Average Feature Request Score",
      "pas": "Billing score\npercentile",
      "abs": "Average Billing Score",
      "ccs": "Comms Score\npercentile",
      "acs": "Average Comms Score",
      "pss": "%{kpis} Health\nPercentile",
      "upss": "Upsell\nPercentile",
      "hfs": "CS Gutfeel\nPercentile",
      "acgs": "Average CS Gutfeel Score"
    }
  },
  "customer_cs_handfeel": {
    "column_headers": {
      "date": "Date",
      "cs_gutfeel_score": "CS Gutfeel Score",
      "customer_cohort_score": "Customer Cohort Score"
    },
    "cs_gutfeel_trend": "CS Gutfeel Trend"
  },
  "cs_handfeel_header": {
    "cs_gutfeel_percentile_score": "CS Gutfeel Percentile Score",
    "type": "Type",
    "customer_cohort_average": "Customer Cohort Average",
    "edit": "Edit"
  },
  "overview_more_details": {
    "yoy_customer_churn": "YoY Customer Churn %",
    "yoy_grr": "YoY GRR %",
    "yoy_nrr": "YoY NRR %",
    "qoq_customer_churn": "QoQ Customer Churn %",
    "qoq_grr": "QoQ GRR %",
    "qoq_nrr": "QoQ NRR %",
    "mom_customer_churn": "MoM Customer Churn %",
    "mom_grr": "MoM GRR %",
    "mom_nrr": "MoM NRR %"
  },
  "success_plan": {
    "title": "Success Plan",
    "total_success_plans": "Total Success Plans",
    "active_success_plans": "Active Success Plans",
    "tab_success_plan": "Success Plan"
  },
  "success_plan_tab": {
    "name": "Name",
    "success_rate": "Success Rate",
    "active": "Active",
    "actions": "Actions",
    "yes": "Yes",
    "no": "No"
  },
  "use_success_plan": {
    "acv": "ACV",
    "arr": "ARR",
    "customer_day_no": "Customer day no.",
    "customer_health": "Customer Health",
    "days_to_renewal": "Days to renewal",
    "kpi": "KPI",
    "previous_task_completed": "On Previous Milestone Task Completion",
    "custom_field": "Custom Field"
  },
  "action_container": {
    "add_actions": "Add Action",
    "csm_action": "CSM Action",
    "auto_action": "Auto Action"
  },
  "action_step": {
    "csm_can_add_comments_for_this_step": "CSM can add comments for this step",
    "csm_can_add_percentage_completion_for_this_step": "CSM can add % completion for this goal"
  },
  "milestone_name": {
    "milestone_number": "Milestone - %{position}"
  },
  "copy_success_plan": {
    "placeholder": "Success-Plan title",
    "save": "Save",
    "cancel": "Cancel"
  },
  "success_plan_log_filter": {
    "title": "Filter",
    "customer_search": "Customer Search",
    "success_plan_name": "Success Plan Name",
    "milestone_name": "Milestone Name",
    "success_plan_completion_percentage": "Success Plan Completion %",
    "milestone_completion_percentage": "Milestone Completion %"
  },
  "success_plan_milestone_todo_steps": {
    "is_action_completed": "Is Action Completed",
    "milestone": "Milestone",
    "milestone_percent_completion": "Milestone % Completion",
    "pending_task": "It seems like you have pending task to complete, do you want to proceed?",
    "milestone_not_achieve": "In order to trigger next milestone you need to complete your milestone 100%, do you want to proceed?",
    "yes": "Yes",
    "no": "No",
    "info": "Info"
  },
  "success_plan_completion": {
    "success_plan_status": "Success plan status:",
    "success_plan_completion_%": "SuccessPlan achievement %:",
    "success_plan_comment": "Achievement Comment",
    "is_completed": "Success plan is completed stop triggering more task",
    "achievement": "Is the business objective of this success plan achieved?",
    "alter_success_plan": "Update Success Plan Achievement Status",
    "yes": "Yes (100%)",
    "no": "No (0%)",
    "partial_param": "Partial (%{percentage}%)",
    "partial": "Partial"
  },
  "chip_filter": {
    "success_plan": "Success Plan: %{name}",
    "milestone_name": "Milestone Name: %{name}",
    "success_plan_completion_percentage": "Success Plan Completion: %{name}",
    "milestone_completion_percentage": "Milestone Completion: %{name}",
    "start_c2s": "Star Customers",
    "star_u2s": "Star Users",
    "all_c2s_unmapped_with_csm": "All customers unmapped with CSM",
    "all_c2s_mapped_with_csm": "All customers mapped with CSM",
    "all_c2s_unmapped_with_account_manager": "All customers unmapped with Account Manager",
    "all_c2s_mapped_with_account_manager": "All customers mapped with Account Manager",
    "all_c2s_unmapped_with_sales_person": "All customers unmapped with Sales Person",
    "all_c2s_mapped_with_sales_person": "All customers mapped with Sales Person",
    "deactivated_users": "Deactivated Users"
  },
  "customer_success_plan_log": {
    "time": "Time",
    "customer": "Customer",
    "start_date": "Milestone Start date",
    "success_plan_end_date": "Success Plan End date",
    "date": "Date",
    "success_plan_%": "Success Plan (%)",
    "milestone_%": "Milestone (%)",
    "csm_action": "CSM Action",
    "csm_comments": "CSM Comments"
  },
  "success_rate_modal": {
    "success_plan_has_run": "No. of times this success plan has run",
    "success_plan_has_achieved": "No. of times this success plan <b>fully</b> achieved the business objective",
    "success_plan_has_achieved_partially": "No. of times this success plan <b>partially</b> achieved the business objective",
    "success_plan_has_failed": "No. of times this success plan <b>completely failed</b> to achieve the business objective",
    "achievement_percentage": "Overall Success Percentage",
    "customers": "Customers",
    "start_date": "Start date",
    "end_date": "End date",
    "current_milestone_name": "Current Milestone Name",
    "current_milestone_completion_percentage": "Milestone Completion %"
  },
  "success_plan_details_modal": {
    "overview": "Overview",
    "start_date": "Start date",
    "end_date": "End date",
    "name": "Name",
    "for_customer": "For customer",
    "status": "Status",
    "in_progress": "In Progress",
    "successfully_achieved": "Successfully Achieved",
    "partially_achieved": "Partially Achieved (%{percentage}%)",
    "failed": "Failed",
    "stop": "Stop",
    "add_milestone": "Add milestone"
  },
  "success_plan_milestone_timeline": {
    "milestone_completed": "Milestone completed",
    "in_queue": "In Queue",
    "suspended": "Suspended"
  },
  "peripheral_mapping_app_types": {
    "saas_product": "SaaS Product",
    "crm": "CRM",
    "bug_tracking_app": "Ticketing",
    "feature_request_app": "Feature Request",
    "subscription_and_payment_app": "Payment System",
    "email_and_communication": "Email And Communication",
    "productivity": "Productivity",
    "meeting": "Meeting"
  },
  "peripheral_transformation_rules": {
    "save_all_confirmation": {
      "alert_title": "Save All Rules",
      "alert_body": "Proceeding to save all rules for %{app_name}. Do you confirm?"
    },
    "check_validation_status_msg": "Checking validation status, please wait...",
    "critical_fields_out_of_sync_warning": "Some critical objects or fields are out of sync, that are required for this transformation mapping",
    "map_zapscale_with": "Map Zapscale with",
    "add_new_mapping": "Add New Mapping",
    "add_field_selection": {
      "add_new_object": "Map new zapscale object",
      "add_new_field_for_existing_object": "Map new field for existing zapscale object"
    },
    "table_headers": {
      "zapscale_object_name": "Zapscale Object Name",
      "zapscale_field_name": "Zapscale Field Name",
      "zapscale_field_desc": "Zapscale Field Description",
      "app_object_name": "Object Name",
      "app_field_name": "Field Name",
      "sync_settings": "Sync Settings",
      "actions": "Actions"
    },
    "validation_not_completed_msg": "Validation process is not yet complete for this app. Please come back after some time.",
    "customer_user_objects_already_mapped": "Zapscale objects \"Customers\" and \"Users\" are already mapped",
    "identity_object_already_mapped": "Zapscale object \"Identity\" is already mapped",
    "transformation_rules_save_success": "Transformation rules saved successfully",

    "error_message": {
      "P1": "priority",
      "closed": "status",
      "bug_feature_type": "type",
      "crm_deal_pipeline": "pipeline"
    },
    "force_to_initial_sync_modal": {
      "title": "On-demand Sync",
      "body": "Do you want to initiate on-demand sync for Zapscale Object: %{zsObject}, Zapscale Field: %{zsField}? Please confirm to proceed",
      "roll_back_confirm": "Yes, I confirm",
      "roll_back_cancel": "Cancel"
    },
    "force_initial_sync_success": "Successfully rolled back to the initially synced data",
    "force_initial_sync_failure": "Failed to roll back to the initially synced data"
  },
  "peripheral_app_archetypes": {
    "option_1": "We have multiple projects for each product. We keep our tickets and features product-wise in these multiple projects.",
    "option_2": "We use ONE project for all tickets and feature requests for all products and customers.",
    "option_3": "We use TWO separate projects.One project for all tickets and second projects for all feature requests for all products and customers.",
    "option_4": "We keep all tickets and all feature requests for all products of ONE customer in ONE project."
  },
  "update_cs_handfeel": {
    "handfeel_updated": "CS Gutfeel score is update and will be reflected from tomorrow"
  },
  "zapscale_to_app_object_mapping_row": {
    "restore": {
      "alert_title": "Restore Zapscale to %{peripheralAppName} Mapping",
      "alert_msg": "Proceeding to restore the field mapping for Zapscale Object: %{zapscaleObject}, Zapscale Field: %{zapscaleField}. Do you confirm?"
    },
    "delete_custom_mapping": {
      "alert_title": "Delete Zapscale to %{peripheralAppName} Mapping",
      "alert_msg": "Proceeding to delete the field mapping for Zapscale Object: %{zapscaleObject}, Zapscale Field: %{zapscaleField}. Do you confirm?"
    },
    "mapping_required": "This mapping is required",
    "mapping_restored_successfully": "Mapping restored successfully",
    "mapping_deleted_successfully": "Mapping deleted successfully",
    "transformation_sync_options": {
      "app_to_zapscale": "App to ZapScale",
      "bi_directional": "Bi-directional Sync",
      "do_not_sync": "Do not Sync"
    },
    "force_initial_sync": "Force Initial Sync"
  },
  "zapscale_to_app_object_association_info_window": {
    "title": "Zapscale to %{peripheralAppName} Object Association",
    "zapscale_object": "Zapscale Object:",
    "existing_mapped_object": "Existing Mapped Object",
    "new_unmapped_object": "New Unmapped Object",
    "existing_mapped_app_object": "Existing Mapped %{peripheralAppName} Object",
    "existing_mapped_app_object_field": "Existing Mapped %{peripheralAppName} Object Field",
    "unmapped_app_object": "Unmapped %{peripheralAppName} Object",
    "unmapped_app_object_field": "Unmapped %{peripheralAppName} Object Field",
    "provide_mapping_information_correctly": "Please provide mapping information correctly"
  },
  "transformation_out_of_sync_info_window": {
    "title": "Out of sync critical fields for %{peripheralAppName}",
    "app_object": "%{peripheralAppName} Object",
    "app_object_status": "%{peripheralAppName} Object Status",
    "app_object_field": "%{peripheralAppName} Object Field",
    "app_object_field_status": "%{peripheralAppName} Object Field Status"
  },
  "add_new_zapscale_object_map_modal": {
    "title": "Add New Zapscale to %{peripheralAppName} Mapping",
    "zapscale": "Zapscale",
    "object": "Object",
    "field": "Field"
  },
  "peripheral_authentication_api_key_modal": {
    "title": "%{peripheralAppName} Authentication",
    "domain": "Domain",
    "api_key": "Api Key",
    "email": "Email",
    "workspace_id": "Workspace ID",
    "project_id": "Project ID",
    "server_location": "Server Location",
    "user_secret": "User Secret",
    "app_id": "App ID",
    "api_value": "Api Value"
  },
  "peripheral_app_object_modal": {
    "title": "%{peripheralAppName} Configuration",
    "app_archetypes": "%{peripheralAppName} Archetypes",
    "app_archetype_selection_msg": "Select the archetype you want to proceed with for your %{peripheralAppName} account",
    "app_projects": "%{peripheralAppName} Projects",
    "app_projects_selection_msg": "Select the project you want ZapScale to pull from your %{peripheralAppName} account",
    "default_objects": "Default Objects",
    "default_object_pull_msg": "ZapScale will pull the following objects by default from your %{peripheralAppName} account",
    "custom_objects": "Custom Objects",
    "custom_objects_pull_msg": "Select the custom objects you want ZapScale to pull from your %{peripheralAppName} account",
    "extraction_via_deals": "Extraction via Deals",
    "extraction_via_deal_msg": "Extract data via deals for %{peripheralAppName} account",
    "data_pull_duration": "Data Pull Duration",
    "data_pull_duration_msg": "How many past days' data you want ZapScale to pull from your %{peripheralAppName} account?",
    "duration_in_days": "Duration (in days)",
    "submit": "Submit",
    "optional_app_objects": "Optional App Objects",
    "optional_objects_pull_msg": "Select the optional objects"
  },
  "peripheral_app_auto_integration_modal": {
    "title": "Auto integrate Linked Apps",
    "selected_archetype": "Selected %{peripheralAppName} Archetype",
    "apps_for_auto_integration_msg": "The following apps are available for auto integration",
    "btn_text": {
      "yes": "Yes, Integrate Linked Apps",
      "no": "No"
    }
  },
  "auto_integration_single_project_selection_item": {
    "select_project": "Select %{peripheralAppName} Projects"
  },
  "cs_handfeel_kpi": {
    "title": "CS Gutfeel KPI's"
  },
  "kpi_list_item": {
    "add_custom_kpi": "Add Custom KPI"
  },
  "kpi_form_container": {
    "title": "Add Custom KPI",
    "next": "Next",
    "previous": "Previous",
    "save": "Save",
    "onSuccess": "%{displayName} created successfully"
  },
  "custom_kpi_name": {
    "name_of_your_custom_kpi": "Name of your custom kpi",
    "type": "Type"
  },
  "custom_kpi_source": {
    "choose_your_custom_kpi_type": "Choose your custom kpi type",
    "ui": "I will record use-cases directly from my UI \n(Zapscale will launce your UI)",
    "api": "I will integrate my APIs for Custom Kpi",
    "csv": "I will provide data from CSV/UI for Custom Kpi",
    "app": "I will use one of the integrated apps to build Custom Kpi"
  },
  "custom_kpi_api_from": {
    "title": "Choose the API",
    "choose_one_of_the_options": "Choose one of the options",
    "1": "Customer info API"
  },
  "custom_kpi_api_fields": {
    "title": "Choose the API Key",
    "choose_one_of_the_options": "Choose one of the option",
    "no_custom_field_title": "You don't have any custom field for the selected api",
    "no_custom_field_description": "Get started by adding your custom field in your selected api"
  },
  "custom_kpi_app": {
    "choose_one_app": "Choose one app from the following integrated apps",
    "no_app_title": "You don't have any app integrated now",
    "no_app_description": "In order to continue integrate a app first",
    "salesforce": "Salesforce",
    "hubspot_crm": "Hubspot CRM",
    "hubspot_crm_accounts": "Hubspot CRM (Extraction via Accounts)",
    "hubspot_crm_deals": "Hubspot CRM (Extraction via Deals)",
    "hubspot_bug": "Hubspot Ticket",
    "hubspot_feature": "Hubspot Feature",
    "pipedrive": "Pipedrive",
    "sugar_crm": "Sugar CRM",
    "ms_dynamics": "MS Dynamics",
    "freshdesk_bug": "Freshdesk Ticket",
    "freshdesk_feature": "Freshdesk Feature",
    "zendesk_bug": "Zendesk Ticket",
    "zendesk_feature": "Zendesk Feature",
    "zoho_crm": "Zoho CRM",
    "zohodesk_bug": "Zohodesk Ticket",
    "zohodesk_feature": "Zohodesk Feature",
    "jira_bug": "Jira Ticket",
    "jira_feature": "Jira Feature",
    "stripe_as_pricing": "Stripe (plan as pricing)",
    "stripe_as_product": "Stripe (plan as product)",
    "paypal": "Paypal",
    "square": "Square",
    "chargebee": "Chargebee",
    "quickbooks": "Quickbooks",
    "gmail": "Gmail",
    "outlook": "Outlook",
    "mailbox": "Mailbox",
    "redmine": "Redmine",
    "zohobooks": "Zohobooks",
    "helpscout_bug": "Help Scout Ticket",
    "helpscout_feature": "Help Scout Feature",
    "no_name": "No Name",
    "csv_ticketing": "Ticketing CSV Integration",
    "csv_crm": "CRM CSV Integration",
    "csv_feature_request": "Feature Request CSV Integration",
    "csv_payment_system": "Subscription & Billing System CSV Integration"
  },
  "custom_kpi_custom_object": {
    "title": "Choose the object",
    "no_custom_object_title": "You don't have any custom object for the selected app",
    "no_custom_object_description": "Get started by adding your custom object"
  },
  "custom_kpi_custom_fields": {
    "title": "Choose one element from the chosen object ",
    "no_fields_title": "You don't have any custom fields associted with the object",
    "no_fields_description": "Get started by adding your custom fields",
    "non-rolling": "Non aggregated non-rolling data",
    "rolling": "Rolling Aggregated Data"
  },
  "custom_kpi_csv_ui": {
    "title": "Choose Custom Field",
    "choose_option": "Choose one of the option",
    "no_fields_title": "You don't have any custom fields integrated",
    "no_fields_description": "Get started by adding your custom fields through UI/CSV"
  },
  "custom_kpi_condition": {
    "title": "How to use the data for building your custom kpi",
    "choose_option": "Choose one of the option"
  },
  "custom_kpi_conditions": {
    "max": "Max value is best",
    "min": "Min value is best",
    "equals": "=",
    "gt": ">",
    "lt": "<",
    "gte": ">=",
    "lte": "<=",
    "between": "Between",
    "ne": "Not",
    "from": "From",
    "to": "To",
    "value": "Value"
  },
  "custom_kpi_range": {
    "title": "Set up Health Range (RED-AMBER-GREEN)",
    "description": "By default zapscale look at all your customers and users and segregates the lowest 10% as RED, middle 20% as AMBER and top 70% as GREEN. You can change this as per your need. Just change the colored bar below and set up your choice"
  },
  "custom_kpi_data_conversion": {
    "title": "How to use data for building your custom kpi?",
    "description": "Choose one of the options",
    "none": "Use the data as is",
    "u2_level": "Convert to per user",
    "usage_hour": "Convert to per hr of product use"
  },
  "custom_kpi_tray_modal": {
    "time": "Time",
    "kpi_score": "KPI percentile (score)",
    "field": "Value",
    "u2": "No of u2s",
    "hours": "No of hours",
    "calculated": "Computed value"
  },
  "users": {
    "title": "User List",
    "user": "User",
    "zapscore": "Zapscore",
    "user_type": "User Type",
    "user_persona": "User Persona",
    "customer": "Customer",
    "product_usage_percentile_score": "Product Usage Percentile Score",
    "filtered_by": "Filtered By",
    "clear": "Clear",
    "filter": "Filter",
    "user_emails": "User Emails",
    "user_deactivated": "User Deactivated"
  },
  "user_contacts":{
    "title":"User Contacts",
    "contact_name":"Contact Name",
    "customer":"Customer",
    "zapscore":"Zapscore",
    "arr":"ARR",
    "renewal_in":"Renewal In",
    "churn_probability":"Churn Probability",
    "customer_type":"Customer Type",
    "is_user":"User",
    "persona":"Persona",
    "job_title":"Job Title",
    "email":"Email",
    "phone":"Phone",
    "email_opt_out":"Email Opt Out",
    "primary_contact":"Primary Contact",
    "filtered_by": "Filtered By"
  },
  "user_modal": {
    "notification": "Notification",
    "open_zapbook": "Open Zapbook"
  },
  "suspect_c2_matcher_tray": {
    "title": "Choose Matchers For Customer Mapping",
    "sub_title": "We will be using the Primary App's Customer data to map the customers across all other apps. Please tell us what are the unique matching points Zapscale can use for auto-Customer-matching",
    "re_run_matching": "Save & Re-run Matching",
    "matcher_subtext": {
      "unique_id": "My Customers have unique IDs across apps",
      "name": "My Customers have unique Names",
      "email": "My Customers have unique Emails",
      "phone": "My Customers have unique Phone numbers",
      "mobile": "My Customers have unique Mobile numbers",
      "location": "My Customers have unique Locations",
      "domain": "My Customers have unique Domains"
    }
  },
  "customer_mapping": {
    "title": "Customer Mapping",
    "show_matchers": "Show Matchers",
    "matchers": "Matchers: ",
    "unconfirmed_matches_count": "Un-confirmed Matches: %{count}",
    "confirmed_matches_count": "Confirmed Matches: %{count}",
    "filter": "Filter",
    "filtered_by": "Filtered By :",
    "clear": "Clear",
    "unmatch_selected_customers": "Un-match Selected Customer(s)",
    "select_for_unmatch_warning": "Please select customer(s) for un-matching",
    "failed_to_fetch_mapping_data": "Failed to fetch mapping data",
    "unmatch_dialog": {
      "title": "Un-match selected customers",
      "body": "Do you want to un-match %{customer_count} customer(s)? Please confirm",
      "body_single_unmatch": "Do you want to un-match \"%{name}\"? Please confirm",
      "confirm_btn": "Un-match",
      "cancel_btn": "Cancel"
    },
    "unmatch_selected_c2_success": "Un-match successful for %{customer_count} customer(s)",
    "unmatch_success": "Successfully un-matched mapping",
    "unmatch_failed": "Failed to un-match mapping",
    "matcher_fetch_failed": "Failed to fetch matchers",
    "unique_peripheral_ids_fetch_failed": "Failed to fetch unique peripheral app ids from suspects",
    "failed_fetch_orphan_customers": "Failed to fetch orphan customers",
    "please_select_customer_for_manual_matching": "Please select a suspect customer for manual mapping",
    "search": "Search",
    "customer_name_search": "Customer Name Search",
    "selected_suspects": "Selected Suspects: ",
    "manual_map_empty_state": {
      "header": "No Unmapped Suspect Found"
    },
    "mapping_empty_state": {
      "header": "No Mapping Found",
      "description": "No customer mapping information found"
    }
  },
  "c2_mapping_filter": {
    "unmatched": "All customers with un-confirmed matches",
    "matched": "All customers with confirmed matches",
    "atleast_one_unmatched": "All customers with at least one un-matched app",
    "all_apps_matched": "All customers with all matched apps"
  },
  "plan_to_product_mapping_dialog": {
    "title": "Plan To Product Mapping",
    "sub_heading": "Please select plan to product mapping for %{app_name}",
    "plans": "Plan(s)",
    "products": "Product(s)",
    "saas_app_products_fetch_failure": "Failed to fetch products",
    "mapping_fetch_failure": "Failed to fetch plan to product mapping",
    "updated_successfully": "\"%{planName}\" plan is mapped with %{productName}",
    "no_product": "no product",
    "update_failed": "Failed to update plan to product mapping",
    "no_plans_found": "Oops... No plan found for %{app_name}...!!!",
    "search_plan": "Search Plan",
    "empty_state_heading": "No Plan to Product Mapping Found",
    "empty_state_description": "No plan to product mapping found for %{appName}",
    "assign_selected_to_all": "Assign selected to all"
  },
  "assign_csm_to_customers_filter": {
    "all_c2s_with_csm": "All customers with CSM",
    "all_c2s_with_account_manager": "All customers with Account Manager",
    "all_c2s_with_sales_person": "All customers with Sales Person",
    "mapped": "Mapped",
    "unmapped": "Unmapped"
  },
  "customer_ticket_info_modal": {
    "ticket_data": "Ticket Data",
    "name": "Name",
    "description": "Description",
    "ticket_id": "Ticket ID",
    "priority": "Priority",
    "status": "Status",
    "logged_by": "Logged By",
    "created_date": "Created Date",
    "updated_date": "Updated Date",
    "ageing": "Ageing",
    "customer_name": "Customer Name",
    "product": "Product",
    "close": "Close"
  },
  "customer_feature_info_modal": {
    "feature_data": "Feature Data",
    "feature_id": "Feature ID"
  },
  "saas_app_customers": {
    "customers": "Customers",
    "customer_data_settings": "Customer Data Settings",
    "none": "No Custom Field",
    "all": "All Custom Field",
    "custom_field_names": "Required Custom Fields in CSV",
    "field_names": "Field Name",
    "custom_fields_in_csv": "Custom Fields in Downloaded CSV",
    "empty_state": {
      "header": "No Customer Found",
      "description": "You may start by uploading customers through csv"
    }
  },
  "c2_data_settings": {
    "condition_with": {
      "c2_status": "Customer status",
      "c2_type": "Customer Type",
      "u2_role": "User Role",
      "c2_start_date": "Customer Start Date",
      "u2_email_domain": "User Email Domain"
    },

    "tracking_status": {
      "is_tracked": "Is Tracked",
      "is_untracked": "Is Untracked"
    },

    "text_with_tracked": {
      "all": "All the customers  need to be tracked",
      "c2id": "Track customers with the following IDs.",
      "c2name": "Track customers with the following names",
      "u2Id": "Track users with the following IDs.",
      "u2Name": "Track users with the following names",
      "u2role": "Track users with the following roles",
      "rule": "Track customers/users who satisfy the following rule"
    },

    "text_with_untracked": {
      "all": "All the customers  need to be untracked",
      "c2id": "Untrack customers with the following IDs.",
      "c2name": "Untrack customers with the following names",
      "u2Id": "Untrack users with the following IDs.",
      "u2Name": "Untrack users with the following names",
      "u2role": "Untrack users with the following roles",
      "rule": "Untrack customers/users who satisfy the following rule"
    },
    "info": "Set up your customer data settings.Only the customers that agree with your settings will be picked up.You can change this any time!",
    "list": {
      "c2ids": "Select IDs from the list",
      "c2names": "Select names from the list",
      "u2roles": "Select roles from the list"
    }
  },
  "app_header": {
    "logout": "Logout",
    "developer_settings": "Developer Settings",
    "manage_account": "Manage Account"
  },
  "developer_setting": {
    "title": "Developer Settings",
    "back": "Back",
    "tabs": {
      "personal_access_token": "Personal Access Token"
    },
    "personal_access_token": {
      "generate_new_token": "Generate New Token",
      "generate_token": "Generate Token",
      "goto_list": "Go to List",
      "list": {
        "heading": "Personal Access Token(s)",
        "table_columns": {
          "name": "Name",
          "expires_at": "Expires At"
        },
        "list_fetch_failed": "Failed to fetch personal access token list",
        "no_data": "No personal access token found",
        "token_deleted_successfully": "Successfully deleted personal access token",
        "failed_to_delete_token": "Failed to delete personal access token"
      },
      "create_new": {
        "heading": "Generate Personal Access Token",
        "name": "Name",
        "expires_at": "Expires At",
        "token_created_successfully": "Your personal access token is generated successfully...!!",
        "failed_to_create_token": "Failed to create personal access token",
        "token_generation_msg": "Token you have generated that can be used to access Zapscale API",
        "make_sure_to_copy": "Make sure to copy your personal access token now. You won't be able to see it again!"
      },
      "delete_alert": {
        "title": "Delete Personal Access Token",
        "body": "Are you sure you want to delete \"%{token_name}\" token? Any applications or scripts using this token will no longer be able to access the Zapscale API. You can not undo this action.",
        "confirm_btn": "I understand, delete this token",
        "cancel_btn": "Cancel"
      }
    }
  },
  "dashboard": {
    "title": "Dashboard",
    "filter_by": "Filter by",
    "empty_header": "Health information not found",
    "empty_table": "Kpi information not found",
    "empty_description": "Please select a different date",
    "bar_chart_section": {
      "c2s": "Customer",
      "u2s": "User"
    }
  },
  "overview_section": {
    "title": "Overview",
    "more_details": "More Details",
    "zapscore": "Zapscore",
    "annual_recurring_revenue": "Annual Recurring Revenue",
    "customers": "Customers",
    "users": "Users"
  },
  "more_details_modal": {
    "yoy_customer_churn": "YoY Customer Churn %",
    "yoy_grr": "YoY GRR %",
    "yoy_nrr": "YoY NRR %",
    "qoq_customer_churn": "QoQ Customer Churn %",
    "qoq_grr": "QoQ GRR %",
    "qoq_nrr": "QoQ NRR %",
    "mom_customer_churn": "MoM Customer Churn %",
    "mom_grr": "MoM GRR %",
    "mom_nrr": "MoM NRR %",
    "software_usage": "Software Usage",
    "total_time_of_usage": "Total time of Usage",
    "mins_customer_time": "Mins - customer/%{time}",
    "mins_user_time": "Mins - user/%{time}",
    "pages_visited": "Pages Visited",
    "total_pages_visited": "Total pages visited",
    "pages_customer_time": "Pages - customer/%{time}",
    "pages_user_time": "Pages - user/%{time}",
    "feature_used": "Feature Used",
    "total_features_used": "Total Features Used",
    "features_customer_time": "Features - customer/%{time}",
    "user_time": "User/%{time}",
    "bugs_logged": "Tickets Logged",
    "no_of_bugs_logged": "No. of tickets logged",
    "bugs_customer_time": "Tickets - customer/%{time}",
    "tasks": "Tasks",
    "total_task_done": "Total Tasks Done",
    "tasks_user_time": "Tasks user/%{time}",
    "messages": "Messages",
    "messages_handled": "Messages Handled",
    "messages_user_time": "Messages user/%{time}",
    "actions": "Actions",
    "actions_done": "Actions Done",
    "actions_user_time": "Actions - user/%{time}",
    "actions_time": "Actions Time",
    "hours_spent": "Hours spent"
  },
  "area_chart_section": {
    "title": "Your Business KPI Trend"
  },
  "bar_chart_section": {
    "title": "Customer / User Health Trend"
  },
  "d3_chart_section": {
    "title": "Revenue Threat vs Health",
    "all": "All Users",
    "decision_maker": "Decision Makers",
    "influencer": "Influencers",
    "normal_user": "Normal users"
  },
  "sankey_chart_section": {
    "title": "Health Movements",
    "customer": "Customer",
    "user": "User"
  },
  "table_section": {
    "kpi": "KPI",
    "kpi_score": "KPI Score",
    "customer_health": "Customer Health"
  },
  "peripheral_app_comm_system": {
    "user_integration_status": "User Integration Status",
    "child_integration_info_window": {
      "title": "Integration selection for %{parentAppName}"
    }
  },
  "comm_system_integration_status_modal": {
    "modal_title": "Comm System Integration Status",
    "columns": {
      "user_name": "User Name",
      "role": "Role",
      "department": "Department",
      "integrated_apps": "Integrated Apps"
    },
    "heading": "Integration status of Comm System for all the users (only User Level Apps)",
    "no_user_found": "No user found",
    "no_integration": "No integration"
  },
  "mailbox_integration_modal": {
    "modal_title": "Mailbox Integration",
    "email_input_placeholder": "Enter company email",
    "email_must_be_unique": "Email ID must be unique",
    "authentication_code_copied_msg": "Copied authentication confirmation code",
    "authentication_url": "Authentication URL",
    "get_authentication_url": "Get Authentication URL",
    "inbox_sync_msg": "Mailbox integration is synced with Inbox. Email communication KPIs will be computed on the communications from your inbox"
  },
  "peripheral_app_selection": {
    "go_to_app_selection": "Go to App Selection",
    "edit_transformation_rules": "Edit Transformation Rules",
    "integration_details": "Integration Details",
    "oauth_authentication_success": "Oauth authentication successful",
    "no_app_selected": "No app selected",
    "edit_integration": "Edit Integration"
  },
  "customer_payment_info_modal": {
    "subscription_and_billing_data": "Subscription & Billing Data",
    "updated_date": "Updated Date",
    "invoice_id": "Invoice ID",
    "invoice_date": "Invoice Date",
    "ageing": "Ageing",
    "ageing_days": "Ageing Days",
    "invoiced_amount": "Invoiced Amount",
    "amount_paid": "Amount Paid",
    "total_amount_paid": "Total Amount Paid",
    "amount_remaining": "Remaining",
    "total_amount_due": "Total Amount Due",
    "due_date": "Due Date",
    "payment_date": "Payment Date",
    "invoice_status": "Invoice Status",
    "close": "Close",
    "payment_status": "Payment Status",
    "product_name": "Product Name",
    "subscription": "Subscription",
    "cadence": "Cadence",
    "day": "days | day | days"
  },
  "customer_custom_field": {
    "title": "More Information",
    "no_record_title": "No custom field information found!",
    "no_record_description": "Please integrate your custom fields to see them here."
  },

  "peripheral_org_id_modal": {
    "title": "%{peripheralAppName} Organization ID",
    "org_id": "Organization ID",
    "item_check": "Do you use pre created items?"
  },
  "dashboard_filter": {
    "all": "All",
    "deleted": "Deleted",
    "customer_type": "Customer Type",
    "zapscale_segment": "Zapscale Segment",
    "customer_segment": "Customer Segment"
  },
  "comm_system_all_data": {
    "column_name": {
      "date": "Date"
    },
    "email_list_modal_title": "Emails on %{emailDate}",
    "comm_apps_data_list_modal_title": "%{appName} communications on %{communicationDate}",
    "email_info": {
      "from": "from",
      "to": "to",
      "cc": "cc",
      "subject": "subject",
      "date": "date",
      "mailed_by": "mailed-by"
    },
    "communication_log": {
      "columns": {
        "title": "Title"
      },
      "no_title": "No title",
      "communication_fetch_failed": "Failed to fetch communications",
      "no_conversation_data_title": "No communication found",
      "no_conversation_data_body": "It seems like there is no communication for the current conversation thread"
    }
  },
  "slack_thread_item": {
    "reply": "reply | reply | replies"
  },
  "customer_detail_overview": {
    "header": {
      "CRM_Data": "CRM Data",
      "Product_Data": "Product Data",
      "Ticket_Data": "Ticket Data",
      "Feature_Request_Data": "Feature Request Data",
      "Subscription_And_Billing_Data": "Subscription And Billing Data",
      "Comms_Data": "Comms Data",
      "zapscale_data": "ZapScale Data"
    },
    "fields": {
      "crm": {
        "label": {
          "name": "name",
          "phone": "phone",
          "email": "email",
          "address": "address"
        },
        "name": {
          "app_C2_name": "app_C2_name",
          "app_C2_phone": "app_C2_phone",
          "app_C2_email": "app_C2_email",
          "app_C2_address": "app_C2_address"
        }
      },
      "ticket": {
        "label": {
          "30_days_of_logged_tickets": "30 days of logged tickets",
          "Resolved_tickets": "Resolved tickets",
          "Unresolved_tickets": "Unresolved tickets",
          "Users_logging_tickets": "Users logging tickets"
        },
        "name": {
          "total_no_of_bugs": "total_no_of_bugs",
          "no_of_bugs_resolved": "no_of_bugs_resolved",
          "Unresolved_tickets": "Unresolved tickets",
          "no_of_u2_logging_bugs": "no_of_u2_logging_bugs"
        }
      },
      "feature": {
        "label": {
          "90_days_of_logged_features": "90 days of logged features",
          "Resolved_tickets": "Resolved tickets",
          "Unresolved_tickets": "Unresolved tickets"
        },
        "name": {
          "no_of_feature_requests": "no_of_feature_requests",
          "no_of_features_resolved": "no_of_features_resolved",
          "Unresolved_features": "Unresolved features"
        }
      },
      "subs": {
        "label": {
          "No_of_invoices_in_90_days ": "No of invoices in 90 days ",
          "Amount_received": "Amount received",
          "Amount_passed_due_date": "Amount passed due date",
          "Plan_Name": "Plan Name",
          "Plan_Cadence": "Plan Cadence",
          "Subscription_Limit": "Subscription Limit"
        },
        "name": {
          "no_of_invoices_overdue_90_days_plus": "no_of_invoices_overdue_90_days_plus",
          "rolling_invoice_value_paid": "rolling_invoice_value_paid",
          "total_overdue_amount": "total_overdue_amount",
          "Plan_Name": "Plan Name",
          "Plan_Cadence": "Plan Cadence",
          "Subscription_Limit": "Subscription Limit"
        }
      },
      "comms": {
        "label": {
          "30_days_of_comm_received": "30 days of comms received",
          "No_comms_since": "No comms since"
        },
        "name": {
          "no_of_emails_or_communication": "no_of_emails_or_communication",
          "days_since_last_communication": "days_since_last_communication"
        }
      },
      "product": {
        "label": {
          "Total_Time_on_Product": "Total Time on Product",
          "Total_Pages_Visited": "Total Pages Visited",
          "Totals_Features_Used": "Totals Features Used",
          "DAU": "DAU",
          "MAU": "MAU",
          "WAU": "WAU"
        },
        "name": {
          "stvcs_total_sessions_time": "stvcs_total_sessions_time",
          "pvps_total_no_of_unique_pages_visited": "pvps_total_no_of_unique_pages_visited",
          "fups_total_no_of_unique_features_used": "fups_total_no_of_unique_features_used",
          "dau": "dau",
          "mau": "mau",
          "wau": "wau"
        }
      }
    },
    "custom": "Custom"
  },
  "peripheral_app_level": {
    "user_level": "User Level",
    "company_level": "Company Level",
    "company_user_level": "Company/User Level"
  },
  "webhook_copied_success": "Webhook url copied successfully",
  "peripheral_product_usage": {
    "app_peripheral_status_update_success": "App peripheral status update success",
    "app_peripheral_status_update_failed": "App peripheral status update failed",
    "transformation": {
      "mapping_fetch_failed": "Failed to fetch product usage mapping data",
      "mapping_update_success": "Mapping updated successfully for Zapscale Filed: %{zapscaleFieldName}",
      "mapping_update_failure": "Failed to update mapping",
      "mapping_create_success": "Mapping created successfully for Zapscale Filed: %{zapscaleFieldName}",
      "mapping_create_failure": "Failed to create mapping",
      "delete_mapping_success": "Deleted mapping for Zapscale Field: %{zapscaleFieldName}",
      "delete_mapping_failure": "Failed to delete mapping",
      "delete_mapping_confirmation": {
        "alert_title": "Delete Mapping",
        "alert_body": "Do you want to delete mapping? Please confirm to proceed.",
        "delete_confirmation": "Yes, I confirm",
        "delete_cancel": "Cancel"
      }
    }
  },
  "c2_documents": {
    "upload_instruction": "Upload document in PDF/DOCX/DOC/TEXT or any other format",
    "upload_document_label": "Upload New Document",
    "document_tag": "Document Tag",
    "document_tag_placeholder": "Enter Document Tag",
    "file_description_tag": "File Description",
    "file_description_placeholder": "Enter File Description"
  },
  "custom_kpi_info": {
    "custom_kpi_source": "Custom Kpi Source",
    "api_info": "Api Info",
    "api_name": "Api Name",
    "api_key_name": "Api Key Name",
    "aggregation_type": "Aggregation Type",
    "integrated_app_info": "Integrated App Info",
    "csv_ui_info": "Csv/Ui Info",
    "selected_field_name": "Selected field name",
    "selected_app_Name": "Selected App Name",
    "selected_app_object:": "Selected App object",
    "selected_app_object_field": "Selected App object Field",
    "data_configuration_info": "Data Configuration Info",
    "how_your_data_is_used": "How your data is used",
    "custom_kpi_rule": "Custom Kpi Rule",
    "health_range_info": "Health Range Info"
  },
  "peripheral_username_password_modal": {
    "mixpanel": {
      "username": "User Name (Service Account)",
      "password": "Password (Service Account Secret)",
      "project_id": "Project ID",
      "is_eu_server": "Intergrate for European Server:"
    },
    "servicenow_bug": {
      "username": "User Name (Instance)",
      "password": "Password (Instance)",
      "instance_url": "Instance URL"
    },
    "servicenow_feature": {
      "username": "User Name (Instance)",
      "password": "Password (Instance)",
      "instance_url": "Instance URL"
    }
  },
  "customer_detail_active_users_graph": {
    "active_users": "Active Users",
    "daily_active_users": "Daily Active Users",
    "monthly_active_users": "Monthly Active Users",
    "weekly_active_users": "Weekly Active Users"
  },
  "edit_customer_info_modal": {
    "acv": "ACV",
    "arr": "ARR",
    "next_renewal_date": "Next Renewal Date",
    "type": "Type",
    "name": "Name",
    "delete": "Delete"
  },
  "edit_user_info_modal": {
    "delete": "Delete",
    "mark_churned": "User Churned",
    "mark_deactivated": "User Deactivated"
  },
  "customer_info_edit_modal": {
    "title": "Edit Customer Fields",
    "edit_acv": "Edit ACV",
    "edit_arr": "Edit ARR",
    "update_custom_field_values": "Update Custom Field Values",
    "no_custom_field": "Oops..!! No custom field found.",
    "custom_field_update_success": "Successfully updated value for custom field \"%{name}\"",
    "custom_field_update_failure": "Failed to update value for custom field \"%{name}\"",
    "custom_field_delete_success": "Successfully deleted value for custom field \"%{name}\"",
    "custom_field_delete_failure": "Failed to delete value for custom field \"%{name}\"",
    "custom_field_created_from": "Created from"
  },
  "add_custom_field_definition_modal": {
    "title": "Create Custom Field",
    "custom_field_name": "Custom Field Name",
    "custom_field_type": "Custom Field Type"
  },
  "saas_app_users": {
    "users": "Users",
    "empty_state": {
      "header": "No User Found",
      "description": "You may start by uploading users through csv"
    }
  },
  "on_boarding_profile": {
    "update_failure": "Failed to update profile information"
  },
  "customers_filter": {
    "trial": "Trial",
    "onboarding": "Onboarding",
    "steady": "Steady",
    "custom_fields": "Custom Fields"
  },
  "users_filter": {
    "decision_maker": "Decision Maker",
    "normal_user": "Normal User",
    "influencer": "Influencer"
  },
  "assign_csm_to_customers": {
    "file_name": "Assigned CSM Information",
    "download": "Download CSM Data",
    "c2_type_updated_manually": "Customer type updated manually"
  },
  "select_custom_field_item": {
    "custom_field_name": "Name",
    "custom_field_value": "Value"
  },
  "transformation_row_color_info": {
    "title": "Transformation mapping row color codes",
    "default_mapping": "Default mapping",
    "user_defined_mapping": "User defined mapping",
    "custom_mapping": "Custom mapping",
    "modified_from_default": "Mapping modified from default",
    "critical_mapping_out_of_sync": "Critical mapping not in sync (blocking condition)",
    "mapping_out_of_sync": "Mapping not in sync (warning condition)",
    "edit_mapping": "Mapping in edit mode"
  },
  "task_comments": {
    "comments": "Comments",
    "placeholder_text": "Write your comments here!"
  },
  "business_meta_data": {
    "preferred_currency": "What is your preferred currency?",
    "aggregation_days": "Business days (Aggregation )",
    "mon": "Mon",
    "tue": "Tue",
    "wed": "Wed",
    "thu": "Thu",
    "fri": "Fri",
    "sat": "Sat",
    "sun": "Sun"
  },
  "c1_currency": {
    "inr": "INR - Indian Rupee",
    "usd": "USD - US Dollar",
    "gbp": "GBP - British Pound",
    "eur": "EUR - Euro"
  },
  "customer_column_config": {
    "zs_default_view": "Zapscale View",
    "zs_views": "Zapscale Defined Views",
    "readonly": "(Readonly)",
    "custom_views": "Custom Views",
    "shared_views": "Admin Shared Views",
    "configure_columns": "Configure Columns",
    "publish": "Publish",
    "delete_current_view": "Delete current view",
    "current_view": "Current View",
    "new_config_name": "New Config Name",
    "column_list_heading": "VISIBLE COLUMNS - DRAG TO REORDER",
    "available_columns": "AVAILABLE COLUMNS",
    "categories": "CATEGORIES",
    "predefined_fields": "Pre-defined fields",
    "custom_fields": "Custom fields",
    "predefined_kpi": "Pre-defined KPI",
    "custom_kpi": "Custom KPI",
    "save": "Save",
    "apply": "Apply",
    "save_apply": "Save & Apply",
    "cancel": "Cancel",
    "field_source": {
      "web_csv": "WEB/CSV",
      "api": "API",
      "peripheral": "PERIPHERAL"
    },
    "field_type": {
      "number": "Number",
      "string": "String",
      "enum": "Enum",
      "date": "Date",
      "boolean": "Boolean",
      "url": "Url"
    }
  },
  "customer_table_cell_system_field": {
    "day": "days | day | days"
  },
  "manage_user_profile": {
    "photo_upload_success": "Profile photo uploaded successfully",
    "photo_remove_success": "Profile photo removed successfully",
    "profile_image_options": {
      "upload_photo": "Upload Photo",
      "change_photo": "Change Photo",
      "remove_photo": "Remove Photo"
    }
  },
  "zapscale_user_list": {
    "table_title": "ZapScale Users",
    "name": "Name",
    "email_id": "Email ID",
    "division": "Division",
    "role": "Role",
    "reporting_manager": "Reporting Manager",
    "access_right": "Access Right",
    "name_email_search": "Search by Name or Email ID",
    "edit_user_title": "Edit ZapScale User",
    "invitation_sent": "Invitation Sent",
    "invite_new_user": "Invite New User",
    "view_organization_tree": "View Organization Tree",
    "access_permissions": "Access Permissions (Set data visibility permission(s) for ZapScale User)",
    "access_permission_types": {
      "financial": "Financial Data"
    }
  },
  "invite_new_user": {
    "access_right_options_msg": {
      "admin": "Admin access right provides system wide access to every feature of ZapScale. Admin users can view all the customer information assign to other users as well. Admin users can invite other user(s) to join ZapScale and are able to assign user hierarchies and access right for other ZapScale users",
      "member": "Member access right provides limited system access to the user. Sections like Settings and Zapbook Performance is not available for member user. Additionally, data visibility restrictions is also imposed on member users for viewing customer information. A member user will be able to view and interact with the customer data assigned to him/her or to any other user(s) who reports to the current member user."
    }
  },
  "edit_user_invitation": {
    "reporting_manager_option_fetch_failed": "Failed to fetch reporting manager options"
  },
  "organization_tree": {
    "dialog_title": "Organization Tree",
    "caption": "Organization hierarchy tree showing all ZapScale Users as per their assignment to their respective reporting manager",
    "empty_title": "No Organization Hierarchy",
    "empty_desc": "No zapscale user found for the current organization",
    "tree_fetch_failed": "Failed to fetch organization tree",
    "search_placeholder": "Filter user sub-tree by name or email"
  },
  "app_unique_field_value_tray_modal": {
    "title": "App Unique Value Selection",
    "stage_name_subheading": "List item naming convention ( [pipeline name]-[stage name] )",
    "selection_heading_types": {
      "priority": "Please select the values for \"P1\" priorities for %{appName}",
      "status": "Please select all the values for \"closed\" status for %{appName}",
      "type": "Please select all \"bug/feature-request\" ticket types for %{appName}",
      "pipeline": "Please select pipelines for %{appName}",
      "opportunity_stage": "Please select the values for \"Opportunity Stage\" for %{appName}",
      "opportunity_type": "Please select the values for \"Opportunity Type\" for %{appName}",
      "upsell_pipeline": "Please select upsell pipelines for %{appName}",
      "renewal_pipeline": "Please select renewal pipelines for %{appName}",
      "upsell_active_deal_status": "Please select upsell active status for %{appName}",
      "upsell_closed_won_deal_status": "Please select upsell closed won status for %{appName}",
      "upsell_closed_lost_deal_status": "Please select upsell closed lost status for %{appName}",
      "renewal_active_deal_status": "Please select renewal active status for %{appName}",
      "renewal_closed_won_deal_status": "Please select renewal closed won status for %{appName}",
      "renewal_closed_lost_deal_status": "Please select renewal closed lost status for %{appName}",
      "upsell_opportunity_stage": "Please select upsell opportunity stage for %{appName}",
      "renewal_opportunity_stage": "Please select renewal opportunity stage for %{appName}",
      "upsell_opportunity_type": "Please select upsell opportunity type for %{appName}",
      "renewal_opportunity_type": "Please select renewal opportunity type for %{appName}",
      "upsell_opportunity_active_deal_status": "Please select upsell opportunity active deal status for %{appName}",
      "upsell_opportunity_closed_won_deal_status": "Please select upsell opportunity closed won deal status for %{appName}",
      "upsell_opportunity_closed_lost_deal_status": "Please select upsell opportunity closed lost deal status for %{appName}",
      "renewal_opportunity_active_deal_status": "Please select renewal opportunity active deal status for %{appName}",
      "renewal_opportunity_closed_won_deal_status": "Please select renewal opportunity closed won deal status for %{appName}",
      "renewal_opportunity_closed_lost_deal_status": "Please select renewal opportunity closed lost deal status for %{appName}",
      "deal_stage": "Please select deal stages for %{appName}",
      "deal_type": "Please select deal types for %{appName}",
      "upsell_deal_stage": "Please select upsell deal stages for %{appName}",
      "upsell_deal_type": "Please select upsell deal types for %{appName}",
      "upsell_active_deal_stage": "Please select upsell active deal stages for %{appName}",
      "upsell_closed_won_deal_stage": "Please select upsell closed won deal stages for %{appName}",
      "upsell_closed_lost_deal_stage": "Please select upsell closed lost deal stages for %{appName}",
      "renewal_deal_stage": "Please select renewal deal stages for %{appName}",
      "renewal_deal_type": "Please select renewal deal types for %{appName}",
      "renewal_active_deal_stage": "Please select renewal active deal stages for %{appName}",
      "renewal_closed_won_deal_stage": "Please select renewal closed won deal stages for %{appName}",
      "renewal_closed_lost_deal_stage": "Please select renewal closed lost deal stages for %{appName}",
      "no_type": "Please select the values for %{appName}"
    },
    "empty_state_heading": "No Value Found",
    "empty_state_description_types": {
      "priority": "No \"P1\" priorities found for %{appName}",
      "status": "No \"closed\" status found for %{appName}",
      "type": "No \"bug/feature-request\" ticket type found for %{appName}",
      "pipeline": "No pipeline found for %{appName}",
      "opportunity_stage": "No opportunity stage found for %{appName}",
      "opportunity_type": "No opportunity type found for %{appName}",
      "upsell_pipeline": "No upsell pipeline found for %{appName}",
      "renewal_pipeline": "No renewal pipeline found for %{appName}",
      "upsell_active_deal_status": "No upsell status found for %{appName}",
      "upsell_closed_won_deal_status": "No upsell status found for %{appName}",
      "upsell_closed_lost_deal_status": "No upsell status found for %{appName}",
      "renewal_active_deal_status": "No renewal status found for %{appName}",
      "renewal_closed_won_deal_status": "No renewal status found for %{appName}",
      "renewal_closed_lost_deal_status": "No renewal status found for %{appName}",
      "upsell_opportunity_stage": "No upsell opportunity stage found for %{appName}",
      "renewal_opportunity_stage": "No renewal opportunity stage found for %{appName}",
      "upsell_opportunity_type": "No upsell opportunity type found for %{appName}",
      "renewal_opportunity_type": "No renewal opportunity type found for %{appName}",
      "upsell_opportunity_active_deal_status": "No upsell opportunity deal status found for %{appName}",
      "upsell_opportunity_closed_won_deal_status": "No upsell opportunity deal status found for %{appName}",
      "upsell_opportunity_closed_lost_deal_status": "No upsell opportunity deal status found for %{appName}",
      "renewal_opportunity_active_deal_status": "No renewal opportunity deal status found for %{appName}",
      "renewal_opportunity_closed_won_deal_status": "No renewal opportunity deal status found for %{appName}",
      "renewal_opportunity_closed_lost_deal_status": "No renewal opportunity deal status found for %{appName}",
      "deal_stage": "No deal stage found for %{appName}",
      "deal_type": "No deal type found for %{appName}",
      "upsell_deal_stage": "No upsell deal stage found for %{appName}",
      "upsell_deal_type": "No upsell deal type found for %{appName}",
      "upsell_active_deal_stage": "No upsell active deal stage found for %{appName}",
      "upsell_closed_won_deal_stage": "No upsell closed won stage found for %{appName}",
      "upsell_closed_lost_deal_stage": "No upsell closed lost stage found for %{appName}",
      "renewal_deal_stage": "No renewal deal stage found for %{appName}",
      "renewal_deal_type": "No renewal deal type found for %{appName}",
      "renewal_active_deal_stage": "No renewal active deal stage found for %{appName}",
      "renewal_closed_won_deal_stage": "No renewal closed won stage found for %{appName}",
      "renewal_closed_lost_deal_stage": "No renewal closed lost stage found for %{appName}",
      "no_type": "No data found for %{appName}"
    }
  },
  "transformation_change_logs": {
    "title": {
      "line_1": "Change Logs",
      "line_2": "ZapScale Object: <strong style='color:teal;'>%{zsObject}</strong> - ZapScale Field: <strong style='color:teal;'>%{zsField}</strong>"
    },
    "close": "Close",
    "empty_state": {
      "heading": "Change log not found",
      "description": "No change log found for current transformation rule"
    },
    "from": "Object: <strong>%{object}</strong> - Field: <strong>%{field}</strong>",
    "to": "Object: <strong>%{object}</strong> - Field: <strong>%{field}</strong>"
  },
  "task_filter": {
    "task_name": "Task Name: %{taskName}"
  },
  "message_filter": {
    "message_name": "Message Topic: %{messageName}"
  },
  "action_filter": {
    "action_name": "Action Topic: %{actionName}"
  },
  "note_filter": {
    "note_title": "Note Title: %{noteTitle}"
  },
  "success_plan_filter": {
    "success_plan_name": "SuccessPlan Name: %{success_plan_name}"
  },
  "permission_scope": {
    "access_denied": "Access Denied"
  },
  "app_notifications": {
    "title": "Notifications",
    "read": "Read",
    "view": "View",
    "read_all": "Read all",
    "download": "Download",
    "csv": "CSV"
  },
  "peripheral_apps_common": {
    "synced_on": "Synced on %{date}"
  },
  "custom_fields_source": {
    "api": "API",
    "web_csv": "WEB/CSV",
    "peripheral": "PERIPHERAL"
  },
  "zapbook_message_list": "Zapbook Message List",
  "no_aggregation_data": {
    "title": "Oops! We See No Data Here!",
    "content": "Looks like you have no data in the chosen date range.\n Select a different date range to view your data.",
    "content_day": "Looks like you have no data in the chosen date.\n Select a different date to view your data."
  },
  "app_footer": {
    "terms_condition": "Terms & Condition",
    "privacy_policy": "Privacy Policy",
    "cookie_policy": "Cookie Policy",
    "compliance": "Compliance"
  },
  "zapbook_successPlan_list": "Zapbook SuccessPlan Lists",
  "customer_mapping_v2": {
    "mapping_type_options": {
      "all_customers_unmapped": "All Unmapped Customers",
      "all_customers_mapped": "All Mapped Customers",
      "atleast_one_customer_unmapped": "Atleast 1 Unmapped Customer",
      "atleast_one_customer_mapped": "Atleast 1 Mapped Customer"
    },
    "filter": "Filter:",
    "selection_labels": {
      "select_application": "Select Application",
      "select_mapping_status": "Select Mapping Status"
    },
    "mapping_status_info": {
      "complete_mapping": "Customer Mapping Complete:",
      "missing_mapping": "Customer Mapping Missing:",
      "mapping_value": "%{percentage}% (%{total_found_mapping}/%{total_expected_mapping})"
    },
    "mapping_count_status": {
      "complete_mapping": "%{complete_percentage}% (%{total_complete_mapping}/%{total_expected_mapping})",
      "missing_mapping": "%{missing_percentage}% (%{total_missing_mapping}/%{total_expected_mapping})"
    },
    "mapping_info_window": {
      "completely_mapped": "Completely mapped with all apps",
      "partially_mapped": "Partially mapped with some apps",
      "no_apps_mapped": "Not mapped with any app"
    },
    "mapping_status_for_saas_c2_all_apps": "Mapping Status for SaaS Customers Across All Apps",
    "mapping_status_for_saas_c2_app_wise": "App Wise Mapping Status for all SaaS Customers",
    "app_wise_mapping_status_table_header": {
      "app_name": "App Name",
      "complete_mapping": "Complete Mapping",
      "missing_mapping": "Missing Mapping"
    }
  },
  "segment_health": {
    "title": "Segment List",
    "table": {
      "segment": "Segment",
      "zs": "Zapscore",
      "acv": "ACV",
      "arr": "ARR",
      "total_customers": "Total Customers",
      "customer_health": "Customer Health",
      "total_users": "Total Users",
      "user_health": "User Health",
      "filtered_by": "Filtered By",
      "filter": "Filter",
      "clear": "Clear"
    }
  },
  "peripheral_data_warehouse": {
    "data_categories": {
      "custom_fields": "Custom Fields",
      "customers": "Customers",
      "csm": "CSMs"
    },
    "integration_success": "%{app_name} integrated successfully",
    "update_success": "%{app_name} configuration updated successfully",
    "configuration_modal": {
      "title": "Data Warehouse Configuration",
      "data_pull_strategy": "Pull Strategy: %{count}",
      "authentication": {
        "title": "Provide your %{peripheral_app_name} credentials below",
        "select_integration_scheme": "Select Integration Scheme",
        "access_key_id": "Access Key ID",
        "secret_access_key": "Secret Access Key",
        "bucket_name": "Bucket Name",
        "region": "Region",
        "api_end_point": "Api End Point",
        "role_arn": "Role ARN",
        "account_id": "Account ID",
        "external_id": "External ID",
        "integration_schemes": {
          "secret_access_key": "Secret Access Key",
          "presigned_url": "Presigned Url",
          "role_based_arn": "Role Based ARN"
        }
      },
      "data_category_configuration": {
        "title": "Set up data pull configurations for data categories"
      },
      "pull_strategies": {
        "uri": "URI",
        "cadence": "Cadence",
        "file_type": "File Type",
        "days": "days",
        "object_key": "Object Key",
        "pull_strategy_name": "Pull Strategy Name"
      },
      "validation_errors": {
        "enabled_data_category_min_strategy_error": "Enabled data category must have atleast one pull strategy",
        "all_categories_disabled_error": "Atleast one data category needs to be enabled for integration",
        "invalid_url_error": "Invalid url",
        "duplicate_strategy_name": "Must be unique. Same as %{value}",
        "pull_strategy": "pull strategy",
        "pull_strategies": "pull strategies",
        "ampersand": "&"
      },
      "empty_state": {
        "title": "Peripheral app not found",
        "message": "It seems like something went wrong."
      }
    },
    "delete_pull_strategy_confirmation": {
      "title": "Delete Pull Strategy",
      "message": "Do you want to delete %{category_name} - Pull Strategy: %{count} (%{ps_name})? Deletion will stop data fetch for this pull strategy from %{peripheral_app} permanently. Please confirm",
      "confirm_btn": "Yes, I Confirm",
      "cancel": "Cancel"
    },
    "transformation": {
      "loading_transformation": "Loading transformation rules, please wait...",
      "incremental": "Added new columns to your file?",
      "refresh_button": "Refresh available data here!",
      "set_custom_field": " Set Custom Fields",
      "unmapped_error": "Please map mandatory field(s)",
      "not_saved_error": "Please save edited mapping(s)",
      "inconsistent_mapping": "Inconsistent mapping info",
      "category_mapping_error": "Please save all mandatory/edited mapping(s) for %{value}",
      "pull_strategy": "Pull Strategy",
      "pull_strategies": "Pull Strategies",
      "custom_field_modal": {
        "subheading": "Select and set mappings for custom fields"
      },
      "pull_strategy_mapping_title": "Please map the transformation rules below for pull strategy:",
      "no_transformation_rules": "No transformation rules found for %{peripheral_app_name}",
      "refetch_transformation_rules": "Re-fetch transformation rules"
    }
  },
  "campaign_audience": {
    "label": "Campaign Audience",
    "customer": "Customer",
    "user": "User"
  },
  "campaign_source": {
    "customer_list": "Customer List",
    "playbook": "Playbook",
    "success_plan": "Success Plan"
  },

  "domain_items": {
    "label": "Domain",
    "set_default": "Set Default",
    "submit": "Submit",
    "validate": "Validate",
    "verified_domain": "Verified Domain",
    "default": "Default",
    "ready_to_use": "Ready to use"
  },

  "campaign_exclusions": {
    "users": "Campaign Exclusion Users",
    "reason": {
      "label": "Reason",
      "non_existent_email": "Non-existent email address",
      "wrong_email": "Wrong email address",
      "unsuscribed": "Unsubscribed",
      "spam_reported": "Spam reported"
    }
  },

  "c2_segment_filter": {
    "all": "All",
    "zapscale": "Zapscale Segment",
    "custom": "User Defined Segment",
    "other": "Other"
  },
  "product_mapping": {
    "title": "Product Mapping",
    "choose_orphan_product_manual_map_msg": {
      "msg_part1": "Choose one from the following Suspect Products list for manually mapping with SaaS Product",
      "msg_part2": "for"
    },
    "suspect_product_not_found": "No Suspect Product found for",
    "suspect_product_mapping_select_one": "Please select atleast one suspect product for manual mapping",
    "manual_product_mapping_failed": "Failed to manually map selected suspect product(s)"
  },
  "custom_kpi_condition_modal": {
    "select_location": "Select Location",
    "select_product": "Select Product",
    "select_customer_segment": "Select Customer Segment",
    "select_customer": "Select Customer",
    "select_customer_type": "Select Customer Type"
  },
  "cs_handfeel_edit_modal": {
    "cs_gutfeel": "CS Gutfeel",
    "placeholder": "Write your comments here!",
    "created_at": "Created At",
    "created_by": "Created by",
    "gutfeel_score": "Gutfeel Score",
    "gutfeel_comments": "Gutfeel Comments",
    "update_gutfeel_info": "Update Gutfeel Info",
    "historical_data_table": "Historical Data Table"
  },
  "inbox": {
    "inbox_integration_msg": "Integration of Inbox will start all email communication KPIs to be computed on the communications from your inbox",
    "inbox_remove_msg": "Removing inbox will stop your email communication KPIs from computation. You need to integrate Mailbox from settings."
  },
  "placeholder_modal": {
    "system_fields": "System Fields",
    "custom_fields": "Custom Fields",
    "system_kpis": "System KPIs",
    "custom_kpis": "Custom KPIs",
    "placeholder": "Placeholder",
    "name": "Name",
    "description": "Description"
  },
  "transformation_sync_options": {
    "app_to_zapscale": "APP TO ZAPSCALE",
    "bi_directional": "BI-DIRECTIONAL",
    "do_not_sync": "DO NOT SYNC"
  },
  "peripheral_task/note_out_of_sync": {
    "status": " Bi-directional sync is not enabled for %{peripheral_object} status",
    "priority": "Bi-directional sync is not enabled for %{peripheral_object} priority",
    "text": "Bi-directional sync is not enabled for %{peripheral_object} description"
  },
  "last_renewal_date_param": "Last Renewal Date: {date}",
  "next_renewal_date_param": "Next Renewal Date: {date}",
  "upsell_deals": {
    "title": "Upsell Deals",
    "table_columns": {
      "creation_date": "Creation Date",
      "customer_name": "Customer Name",
      "deal_name": "Deal Name",
      "deal_amount": "Deal Amount",
      "expected_close_date": "Close Date",
      "deal_pipeline": "Deal Pipeline",
      "status": "Deal Status",
      "closed_amount": "Closed Amount",
      "deal_point_of_contact": "Point of Contact",
      "deal_owner": "Deal Owner",
      "deal_notes": "Deal Notes",
      "assigned_csms": "Assigned CSM(s)",
      "updated_at": "Updated At",
      "ageing": "Ageing"
    },
    "view": "View",
    "deal_name": "Deal Name: {dealName}",
    "close_date": "Close Date: {date}",
    "created_date": "Created Date: {date}",
    "deal_amount": "Deal Amount: {amount}",
    "closed_amount": "Closed Amount: {amount}",
    "deal_status": "Deal Status: {dealStatus}",
    "deal_pipeline": "Deal Pipeline: {dealPipeline}",
    "assigned_to": "Assigned To: {name}",
    "filter_title": "Upsell Deals Filter",
    "deal_owners": "Deal Owners:",
    "deal_owner": "Deal Owner: {name}",
    "deal_customers": "Deal Customers:",
    "deal_customer": "Customer: {name}"
  },
  "renewal_deals": {
    "title": "Renewal Deals",
    "deal_name": "Deal Name: {dealName}",
    "close_date": "Close Date: {date}",
    "created_date": "Created Date: {date}",
    "deal_amount": "Deal Amount: {amount}",
    "closed_amount": "Closed Amount: {amount}",
    "deal_status": "Deal Status: {dealStatus}",
    "deal_pipeline": "Deal Pipeline: {dealPipeline}",
    "assigned_to": "Assigned To: {name}",
    "filter_title": "Renewal Deals Filter",
    "deal_owners": "Deal Owners:",
    "deal_owner": "Deal Owner: {name}",
    "deal_customers": "Deal Customers:",
    "deal_customer": "Customer: {name}"
  },
  "deal_history_dialog": {
    "upsell_title": "Upsell Deal Details",
    "renewal_title": "Renewal Deal Details",
    "creation_date": "Deal Creation Date",
    "deal_owner": "Deal Owner",
    "deal_pipeline": "Deal Pipeline",
    "deal_status": "Deal Status",
    "deal_amount": "Deal Amount",
    "deal_closed_amount": "Deal Closed Amount",
    "deal_close_date": "Deal Close Date",
    "customer_name": "Customer Name",
    "assigned_csms": "Assigned CSM(s)",
    "deals_movement": "Deal movement for ",
    "deal_movement_not_found": "No deal movement found",
    "days": "0 day | 1 day | {count} days"
  },
  "ticketing_info_file_name": "Ticketing Information",
  "task_file_under_customer_details": "Customer Task List",
  "task_file_under_user_details": "User Task List",
  "date": "Date",
  "start_date": "Start Date",
  "end_date": "End Date",
  "deactivated_users": "Deactivated Users",
  "surveys": {
    "survey_tab": {
      "overview": "Overview",
      "nps_surveys": "NPS Surveys",
      "csat_surveys": "CSAT Surveys"
    },
    "nps_header": {
      "title": "NPS Surveys"
    },
    "csat_header": {
      "title": "CSAT Surveys"
    },
    "survey_table_columns": {
      "name": "Name",
      "start_date": "Start Date",
      "end_date": "End Date",
      "status": "Campaign Status",
      "nps": "NPS",
      "csat": "CSAT",
      "user": "User",
      "responded": "Responded",
      "detractors": "Detractors",
      "neutral": "Neutral",
      "passives": "Passives",
      "promoters": "Promoters",
      "satisfied": "Satisfied",
      "dissatisfied": "Dissatisfied",
      "actions": "Actions"
    },
    "surveys_filter": {
      "survey_name": "Survey Name: {surveyName}",
      "campaign_status": "Campaign Status: {status}",
      "nps": "NPS: {nps}",
      "csat": "CSAT: {csat}",
      "responder_type": "Responder Type: {type}",
      "start_date": "Start Date: {date}",
      "end_date": "End Date: {date}",
      "promoters_percent": "Promoters Percent: {range}",
      "neutral_percent": "Neutral Percent: {range}",
      "detractors_percent": "Detractors Percent: {range}",
      "dissatisfied_percent": "Dissatisfied Percent: {range}",
      "satisfied_percent": "Satisfied Percent: {range}"
    }
  },
  "user_state": "User state: %{user_state}",
  "options": "Options",
  "contact_filter": {
    "contact_name": "Contact name like: %{name}",
    "job_title":"Job title like: %{job_title}",
    "is_user":"Is an User",
    "u2_app_emails":"Email: %{u2_app_emails}",
    "is_primary_contact":"Contacts: %{is_primary_contact}"
  }
}
