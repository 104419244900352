/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CopySurveyDto } from '../model/';
// @ts-ignore
import { CreateSurveyDto } from '../model/';
// @ts-ignore
import { Survey } from '../model/';
// @ts-ignore
import { SurveyQuestion } from '../model/';
// @ts-ignore
import { SurveyResponseFilterDto } from '../model/';
// @ts-ignore
import { SurveyResponseOverviewDto } from '../model/';
// @ts-ignore
import { SurveysFilterDto } from '../model/';
// @ts-ignore
import { SurveysPaginationDto } from '../model/';
// @ts-ignore
import { U2SurveyResponse } from '../model/';
// @ts-ignore
import { UpdateSurveyFlagDto } from '../model/';
/**
 * SurveysApi - axios parameter creator
 * @export
 */
export const SurveysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy survey from another survey by id
         * @param {string} id 
         * @param {CopySurveyDto} copySurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerCopySurvey: async (id: string, copySurveyDto: CopySurveyDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveysControllerCopySurvey', 'id', id)
            // verify required parameter 'copySurveyDto' is not null or undefined
            assertParamExists('surveysControllerCopySurvey', 'copySurveyDto', copySurveyDto)
            const localVarPath = `/api/surveys/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copySurveyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerCreate: async (createSurveyDto: CreateSurveyDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSurveyDto' is not null or undefined
            assertParamExists('surveysControllerCreate', 'createSurveyDto', createSurveyDto)
            const localVarPath = `/api/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSurveyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft delete survey by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerDeleteSurvey: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveysControllerDeleteSurvey', 'id', id)
            const localVarPath = `/api/surveys/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch paginated list of surveys along with survey responses
         * @param {SurveysFilterDto} surveysFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAllWithPagination: async (surveysFilterDto: SurveysFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveysFilterDto' is not null or undefined
            assertParamExists('surveysControllerFindAllWithPagination', 'surveysFilterDto', surveysFilterDto)
            const localVarPath = `/api/surveys/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveysFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindOne: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindOne', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyQuestions: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindSurveyQuestions', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}/questions`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find survey response overview for all surveys according to date selection
         * @param {SurveyResponseFilterDto} surveyResponseFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyResponseOverview: async (surveyResponseFilterDto: SurveyResponseFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyResponseFilterDto' is not null or undefined
            assertParamExists('surveysControllerFindSurveyResponseOverview', 'surveyResponseFilterDto', surveyResponseFilterDto)
            const localVarPath = `/api/surveys/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyResponseFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch survey responses by survey id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyResponses: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerFindSurveyResponses', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}/responses`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique responders count, with optional survey id field
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindUniqueResponders: async (xTenantId?: string, surveyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/surveys/unique/responders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveyId !== undefined) {
                localVarQueryParameter['surveyId'] = surveyId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} u2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerPending: async (tenantId: string, u2Id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('surveysControllerPending', 'tenantId', tenantId)
            // verify required parameter 'u2Id' is not null or undefined
            assertParamExists('surveysControllerPending', 'u2Id', u2Id)
            const localVarPath = `/api/surveys/pending/{tenantId}/{u2Id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"u2Id"}}`, encodeURIComponent(String(u2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerPublish: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerPublish', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}/publish`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerQuestions: async (tenantId: string, surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('surveysControllerQuestions', 'tenantId', tenantId)
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerQuestions', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/questions/{tenantId}/{surveyId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} u2Id 
         * @param {string} questionId 
         * @param {string} response 
         * @param {string} isPreview 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerRespond: async (tenantId: string, surveyId: string, u2Id: string, questionId: string, response: string, isPreview: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('surveysControllerRespond', 'tenantId', tenantId)
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerRespond', 'surveyId', surveyId)
            // verify required parameter 'u2Id' is not null or undefined
            assertParamExists('surveysControllerRespond', 'u2Id', u2Id)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('surveysControllerRespond', 'questionId', questionId)
            // verify required parameter 'response' is not null or undefined
            assertParamExists('surveysControllerRespond', 'response', response)
            // verify required parameter 'isPreview' is not null or undefined
            assertParamExists('surveysControllerRespond', 'isPreview', isPreview)
            const localVarPath = `/api/surveys/respond/{tenantId}/{surveyId}/{questionId}/{u2Id}/{response}/{isPreview}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)))
                .replace(`{${"u2Id"}}`, encodeURIComponent(String(u2Id)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)))
                .replace(`{${"response"}}`, encodeURIComponent(String(response)))
                .replace(`{${"isPreview"}}`, encodeURIComponent(String(isPreview)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerSendSurveyEmail: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerSendSurveyEmail', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}/send`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle survey on_off_flag by id
         * @param {string} id 
         * @param {UpdateSurveyFlagDto} updateSurveyFlagDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerToggleSurvey: async (id: string, updateSurveyFlagDto: UpdateSurveyFlagDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveysControllerToggleSurvey', 'id', id)
            // verify required parameter 'updateSurveyFlagDto' is not null or undefined
            assertParamExists('surveysControllerToggleSurvey', 'updateSurveyFlagDto', updateSurveyFlagDto)
            const localVarPath = `/api/surveys/{id}/toggle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSurveyFlagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerUnpublish: async (surveyId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerUnpublish', 'surveyId', surveyId)
            const localVarPath = `/api/surveys/{surveyId}/unpublish`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerUpdate: async (surveyId: string, createSurveyDto: CreateSurveyDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('surveysControllerUpdate', 'surveyId', surveyId)
            // verify required parameter 'createSurveyDto' is not null or undefined
            assertParamExists('surveysControllerUpdate', 'createSurveyDto', createSurveyDto)
            const localVarPath = `/api/surveys/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSurveyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveysApi - functional programming interface
 * @export
 */
export const SurveysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy survey from another survey by id
         * @param {string} id 
         * @param {CopySurveyDto} copySurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerCopySurvey(id: string, copySurveyDto: CopySurveyDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerCopySurvey(id, copySurveyDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerCreate(createSurveyDto: CreateSurveyDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerCreate(createSurveyDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft delete survey by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerDeleteSurvey(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerDeleteSurvey(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Survey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch paginated list of surveys along with survey responses
         * @param {SurveysFilterDto} surveysFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindAllWithPagination(surveysFilterDto: SurveysFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveysPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindAllWithPagination(surveysFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindOne(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindOne(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindSurveyQuestions(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyQuestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindSurveyQuestions(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find survey response overview for all surveys according to date selection
         * @param {SurveyResponseFilterDto} surveyResponseFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindSurveyResponseOverview(surveyResponseFilterDto: SurveyResponseFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyResponseOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindSurveyResponseOverview(surveyResponseFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch survey responses by survey id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindSurveyResponses(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2SurveyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindSurveyResponses(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique responders count, with optional survey id field
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerFindUniqueResponders(xTenantId?: string, surveyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerFindUniqueResponders(xTenantId, surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} u2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerPending(tenantId: string, u2Id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerPending(tenantId, u2Id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerPublish(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerPublish(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerQuestions(tenantId: string, surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyQuestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerQuestions(tenantId, surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} u2Id 
         * @param {string} questionId 
         * @param {string} response 
         * @param {string} isPreview 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerRespond(tenantId: string, surveyId: string, u2Id: string, questionId: string, response: string, isPreview: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U2SurveyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerRespond(tenantId, surveyId, u2Id, questionId, response, isPreview, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerSendSurveyEmail(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerSendSurveyEmail(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Toggle survey on_off_flag by id
         * @param {string} id 
         * @param {UpdateSurveyFlagDto} updateSurveyFlagDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerToggleSurvey(id: string, updateSurveyFlagDto: UpdateSurveyFlagDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerToggleSurvey(id, updateSurveyFlagDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerUnpublish(surveyId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerUnpublish(surveyId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveysControllerUpdate(surveyId: string, createSurveyDto: CreateSurveyDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveysControllerUpdate(surveyId, createSurveyDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveysApi - factory interface
 * @export
 */
export const SurveysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveysApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy survey from another survey by id
         * @param {string} id 
         * @param {CopySurveyDto} copySurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerCopySurvey(id: string, copySurveyDto: CopySurveyDto, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerCopySurvey(id, copySurveyDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerCreate(createSurveyDto: CreateSurveyDto, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerCreate(createSurveyDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft delete survey by id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerDeleteSurvey(id: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.surveysControllerDeleteSurvey(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<Survey>> {
            return localVarFp.surveysControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch paginated list of surveys along with survey responses
         * @param {SurveysFilterDto} surveysFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindAllWithPagination(surveysFilterDto: SurveysFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<SurveysPaginationDto> {
            return localVarFp.surveysControllerFindAllWithPagination(surveysFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindOne(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerFindOne(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyQuestions(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Array<SurveyQuestion>> {
            return localVarFp.surveysControllerFindSurveyQuestions(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find survey response overview for all surveys according to date selection
         * @param {SurveyResponseFilterDto} surveyResponseFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyResponseOverview(surveyResponseFilterDto: SurveyResponseFilterDto, xTenantId?: string, options?: any): AxiosPromise<Array<SurveyResponseOverviewDto>> {
            return localVarFp.surveysControllerFindSurveyResponseOverview(surveyResponseFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch survey responses by survey id
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindSurveyResponses(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Array<U2SurveyResponse>> {
            return localVarFp.surveysControllerFindSurveyResponses(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique responders count, with optional survey id field
         * @param {string} [xTenantId] 
         * @param {string} [surveyId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerFindUniqueResponders(xTenantId?: string, surveyId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.surveysControllerFindUniqueResponders(xTenantId, surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} u2Id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerPending(tenantId: string, u2Id: string, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerPending(tenantId, u2Id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerPublish(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerPublish(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerQuestions(tenantId: string, surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Array<SurveyQuestion>> {
            return localVarFp.surveysControllerQuestions(tenantId, surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} surveyId 
         * @param {string} u2Id 
         * @param {string} questionId 
         * @param {string} response 
         * @param {string} isPreview 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerRespond(tenantId: string, surveyId: string, u2Id: string, questionId: string, response: string, isPreview: string, xTenantId?: string, options?: any): AxiosPromise<U2SurveyResponse> {
            return localVarFp.surveysControllerRespond(tenantId, surveyId, u2Id, questionId, response, isPreview, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerSendSurveyEmail(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.surveysControllerSendSurveyEmail(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Toggle survey on_off_flag by id
         * @param {string} id 
         * @param {UpdateSurveyFlagDto} updateSurveyFlagDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerToggleSurvey(id: string, updateSurveyFlagDto: UpdateSurveyFlagDto, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerToggleSurvey(id, updateSurveyFlagDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerUnpublish(surveyId: string, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerUnpublish(surveyId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} surveyId 
         * @param {CreateSurveyDto} createSurveyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysControllerUpdate(surveyId: string, createSurveyDto: CreateSurveyDto, xTenantId?: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.surveysControllerUpdate(surveyId, createSurveyDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for surveysControllerCopySurvey operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerCopySurveyRequest
 */
export interface SurveysApiSurveysControllerCopySurveyRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerCopySurvey
     */
    readonly id: string

    /**
     * 
     * @type {CopySurveyDto}
     * @memberof SurveysApiSurveysControllerCopySurvey
     */
    readonly copySurveyDto: CopySurveyDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerCopySurvey
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerCreate operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerCreateRequest
 */
export interface SurveysApiSurveysControllerCreateRequest {
    /**
     * 
     * @type {CreateSurveyDto}
     * @memberof SurveysApiSurveysControllerCreate
     */
    readonly createSurveyDto: CreateSurveyDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerDeleteSurvey operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerDeleteSurveyRequest
 */
export interface SurveysApiSurveysControllerDeleteSurveyRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerDeleteSurvey
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerDeleteSurvey
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindAll operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindAllRequest
 */
export interface SurveysApiSurveysControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindAllWithPagination operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindAllWithPaginationRequest
 */
export interface SurveysApiSurveysControllerFindAllWithPaginationRequest {
    /**
     * 
     * @type {SurveysFilterDto}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly surveysFilterDto: SurveysFilterDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindAllWithPagination
     */
    readonly after?: string
}

/**
 * Request parameters for surveysControllerFindOne operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindOneRequest
 */
export interface SurveysApiSurveysControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindOne
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindSurveyQuestions operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindSurveyQuestionsRequest
 */
export interface SurveysApiSurveysControllerFindSurveyQuestionsRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyQuestions
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyQuestions
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindSurveyResponseOverview operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindSurveyResponseOverviewRequest
 */
export interface SurveysApiSurveysControllerFindSurveyResponseOverviewRequest {
    /**
     * 
     * @type {SurveyResponseFilterDto}
     * @memberof SurveysApiSurveysControllerFindSurveyResponseOverview
     */
    readonly surveyResponseFilterDto: SurveyResponseFilterDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponseOverview
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindSurveyResponses operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindSurveyResponsesRequest
 */
export interface SurveysApiSurveysControllerFindSurveyResponsesRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponses
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindSurveyResponses
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerFindUniqueResponders operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerFindUniqueRespondersRequest
 */
export interface SurveysApiSurveysControllerFindUniqueRespondersRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindUniqueResponders
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof SurveysApiSurveysControllerFindUniqueResponders
     */
    readonly surveyId?: string
}

/**
 * Request parameters for surveysControllerPending operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerPendingRequest
 */
export interface SurveysApiSurveysControllerPendingRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPending
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPending
     */
    readonly u2Id: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPending
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerPublish operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerPublishRequest
 */
export interface SurveysApiSurveysControllerPublishRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPublish
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerPublish
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerQuestions operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerQuestionsRequest
 */
export interface SurveysApiSurveysControllerQuestionsRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerQuestions
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerQuestions
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerQuestions
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerRespond operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerRespondRequest
 */
export interface SurveysApiSurveysControllerRespondRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly u2Id: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly questionId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly response: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly isPreview: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerRespond
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerSendSurveyEmail operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerSendSurveyEmailRequest
 */
export interface SurveysApiSurveysControllerSendSurveyEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerSendSurveyEmail
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerSendSurveyEmail
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerToggleSurvey operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerToggleSurveyRequest
 */
export interface SurveysApiSurveysControllerToggleSurveyRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerToggleSurvey
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSurveyFlagDto}
     * @memberof SurveysApiSurveysControllerToggleSurvey
     */
    readonly updateSurveyFlagDto: UpdateSurveyFlagDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerToggleSurvey
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerUnpublish operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerUnpublishRequest
 */
export interface SurveysApiSurveysControllerUnpublishRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerUnpublish
     */
    readonly surveyId: string

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerUnpublish
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for surveysControllerUpdate operation in SurveysApi.
 * @export
 * @interface SurveysApiSurveysControllerUpdateRequest
 */
export interface SurveysApiSurveysControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerUpdate
     */
    readonly surveyId: string

    /**
     * 
     * @type {CreateSurveyDto}
     * @memberof SurveysApiSurveysControllerUpdate
     */
    readonly createSurveyDto: CreateSurveyDto

    /**
     * 
     * @type {string}
     * @memberof SurveysApiSurveysControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * SurveysApi - object-oriented interface
 * @export
 * @class SurveysApi
 * @extends {BaseAPI}
 */
export class SurveysApi extends BaseAPI {
    /**
     * 
     * @summary Copy survey from another survey by id
     * @param {SurveysApiSurveysControllerCopySurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerCopySurvey(requestParameters: SurveysApiSurveysControllerCopySurveyRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerCopySurvey(requestParameters.id, requestParameters.copySurveyDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerCreate(requestParameters: SurveysApiSurveysControllerCreateRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerCreate(requestParameters.createSurveyDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft delete survey by id
     * @param {SurveysApiSurveysControllerDeleteSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerDeleteSurvey(requestParameters: SurveysApiSurveysControllerDeleteSurveyRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerDeleteSurvey(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindAll(requestParameters: SurveysApiSurveysControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch paginated list of surveys along with survey responses
     * @param {SurveysApiSurveysControllerFindAllWithPaginationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindAllWithPagination(requestParameters: SurveysApiSurveysControllerFindAllWithPaginationRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindAllWithPagination(requestParameters.surveysFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindOne(requestParameters: SurveysApiSurveysControllerFindOneRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindOne(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerFindSurveyQuestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindSurveyQuestions(requestParameters: SurveysApiSurveysControllerFindSurveyQuestionsRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindSurveyQuestions(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find survey response overview for all surveys according to date selection
     * @param {SurveysApiSurveysControllerFindSurveyResponseOverviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindSurveyResponseOverview(requestParameters: SurveysApiSurveysControllerFindSurveyResponseOverviewRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindSurveyResponseOverview(requestParameters.surveyResponseFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch survey responses by survey id
     * @param {SurveysApiSurveysControllerFindSurveyResponsesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindSurveyResponses(requestParameters: SurveysApiSurveysControllerFindSurveyResponsesRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindSurveyResponses(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique responders count, with optional survey id field
     * @param {SurveysApiSurveysControllerFindUniqueRespondersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerFindUniqueResponders(requestParameters: SurveysApiSurveysControllerFindUniqueRespondersRequest = {}, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerFindUniqueResponders(requestParameters.xTenantId, requestParameters.surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerPendingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerPending(requestParameters: SurveysApiSurveysControllerPendingRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerPending(requestParameters.tenantId, requestParameters.u2Id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerPublishRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerPublish(requestParameters: SurveysApiSurveysControllerPublishRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerPublish(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerQuestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerQuestions(requestParameters: SurveysApiSurveysControllerQuestionsRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerQuestions(requestParameters.tenantId, requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerRespondRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerRespond(requestParameters: SurveysApiSurveysControllerRespondRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerRespond(requestParameters.tenantId, requestParameters.surveyId, requestParameters.u2Id, requestParameters.questionId, requestParameters.response, requestParameters.isPreview, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerSendSurveyEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerSendSurveyEmail(requestParameters: SurveysApiSurveysControllerSendSurveyEmailRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerSendSurveyEmail(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Toggle survey on_off_flag by id
     * @param {SurveysApiSurveysControllerToggleSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerToggleSurvey(requestParameters: SurveysApiSurveysControllerToggleSurveyRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerToggleSurvey(requestParameters.id, requestParameters.updateSurveyFlagDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerUnpublishRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerUnpublish(requestParameters: SurveysApiSurveysControllerUnpublishRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerUnpublish(requestParameters.surveyId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveysApiSurveysControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public surveysControllerUpdate(requestParameters: SurveysApiSurveysControllerUpdateRequest, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).surveysControllerUpdate(requestParameters.surveyId, requestParameters.createSurveyDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
