/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C1 } from '../model/';
// @ts-ignore
import { C1MailSendUpdateDto } from '../model/';
// @ts-ignore
import { Membership } from '../model/';
// @ts-ignore
import { UpdateC1ActiveDays } from '../model/';
// @ts-ignore
import { UpdateC1Dto } from '../model/';
/**
 * C1Api - axios parameter creator
 * @export
 */
export const C1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get C1 for the authenticated user
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerFindOne: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerMemberships: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c1/memberships`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update C1 for the authenticated user
         * @param {UpdateC1Dto} updateC1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerUpdate: async (updateC1Dto: UpdateC1Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateC1Dto' is not null or undefined
            assertParamExists('c1ControllerUpdate', 'updateC1Dto', updateC1Dto)
            const localVarPath = `/api/c1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update aggregation active days info
         * @param {string} id 
         * @param {UpdateC1ActiveDays} updateC1ActiveDays 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerUpdateActiveDays: async (id: string, updateC1ActiveDays: UpdateC1ActiveDays, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c1ControllerUpdateActiveDays', 'id', id)
            // verify required parameter 'updateC1ActiveDays' is not null or undefined
            assertParamExists('c1ControllerUpdateActiveDays', 'updateC1ActiveDays', updateC1ActiveDays)
            const localVarPath = `/api/c1/aggregation-days/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC1ActiveDays, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update campaign mail quota
         * @param {C1MailSendUpdateDto} c1MailSendUpdateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerUpdateMailSendQuota: async (c1MailSendUpdateDto: C1MailSendUpdateDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c1MailSendUpdateDto' is not null or undefined
            assertParamExists('c1ControllerUpdateMailSendQuota', 'c1MailSendUpdateDto', c1MailSendUpdateDto)
            const localVarPath = `/api/c1/update-mail-send-quota`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c1MailSendUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C1Api - functional programming interface
 * @export
 */
export const C1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get C1 for the authenticated user
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1ControllerFindOne(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1ControllerFindOne(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1ControllerMemberships(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Membership>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1ControllerMemberships(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update C1 for the authenticated user
         * @param {UpdateC1Dto} updateC1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1ControllerUpdate(updateC1Dto: UpdateC1Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1ControllerUpdate(updateC1Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update aggregation active days info
         * @param {string} id 
         * @param {UpdateC1ActiveDays} updateC1ActiveDays 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1ControllerUpdateActiveDays(id: string, updateC1ActiveDays: UpdateC1ActiveDays, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1ControllerUpdateActiveDays(id, updateC1ActiveDays, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update campaign mail quota
         * @param {C1MailSendUpdateDto} c1MailSendUpdateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c1ControllerUpdateMailSendQuota(c1MailSendUpdateDto: C1MailSendUpdateDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c1ControllerUpdateMailSendQuota(c1MailSendUpdateDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C1Api - factory interface
 * @export
 */
export const C1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Get C1 for the authenticated user
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerFindOne(xTenantId?: string, options?: any): AxiosPromise<C1> {
            return localVarFp.c1ControllerFindOne(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerMemberships(xTenantId?: string, options?: any): AxiosPromise<Array<Membership>> {
            return localVarFp.c1ControllerMemberships(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update C1 for the authenticated user
         * @param {UpdateC1Dto} updateC1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerUpdate(updateC1Dto: UpdateC1Dto, xTenantId?: string, options?: any): AxiosPromise<C1> {
            return localVarFp.c1ControllerUpdate(updateC1Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update aggregation active days info
         * @param {string} id 
         * @param {UpdateC1ActiveDays} updateC1ActiveDays 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerUpdateActiveDays(id: string, updateC1ActiveDays: UpdateC1ActiveDays, xTenantId?: string, options?: any): AxiosPromise<C1> {
            return localVarFp.c1ControllerUpdateActiveDays(id, updateC1ActiveDays, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update campaign mail quota
         * @param {C1MailSendUpdateDto} c1MailSendUpdateDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c1ControllerUpdateMailSendQuota(c1MailSendUpdateDto: C1MailSendUpdateDto, xTenantId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.c1ControllerUpdateMailSendQuota(c1MailSendUpdateDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c1ControllerFindOne operation in C1Api.
 * @export
 * @interface C1ApiC1ControllerFindOneRequest
 */
export interface C1ApiC1ControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C1ApiC1ControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1ControllerMemberships operation in C1Api.
 * @export
 * @interface C1ApiC1ControllerMembershipsRequest
 */
export interface C1ApiC1ControllerMembershipsRequest {
    /**
     * 
     * @type {string}
     * @memberof C1ApiC1ControllerMemberships
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1ControllerUpdate operation in C1Api.
 * @export
 * @interface C1ApiC1ControllerUpdateRequest
 */
export interface C1ApiC1ControllerUpdateRequest {
    /**
     * 
     * @type {UpdateC1Dto}
     * @memberof C1ApiC1ControllerUpdate
     */
    readonly updateC1Dto: UpdateC1Dto

    /**
     * 
     * @type {string}
     * @memberof C1ApiC1ControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1ControllerUpdateActiveDays operation in C1Api.
 * @export
 * @interface C1ApiC1ControllerUpdateActiveDaysRequest
 */
export interface C1ApiC1ControllerUpdateActiveDaysRequest {
    /**
     * 
     * @type {string}
     * @memberof C1ApiC1ControllerUpdateActiveDays
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC1ActiveDays}
     * @memberof C1ApiC1ControllerUpdateActiveDays
     */
    readonly updateC1ActiveDays: UpdateC1ActiveDays

    /**
     * 
     * @type {string}
     * @memberof C1ApiC1ControllerUpdateActiveDays
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c1ControllerUpdateMailSendQuota operation in C1Api.
 * @export
 * @interface C1ApiC1ControllerUpdateMailSendQuotaRequest
 */
export interface C1ApiC1ControllerUpdateMailSendQuotaRequest {
    /**
     * 
     * @type {C1MailSendUpdateDto}
     * @memberof C1ApiC1ControllerUpdateMailSendQuota
     */
    readonly c1MailSendUpdateDto: C1MailSendUpdateDto

    /**
     * 
     * @type {string}
     * @memberof C1ApiC1ControllerUpdateMailSendQuota
     */
    readonly xTenantId?: string
}

/**
 * C1Api - object-oriented interface
 * @export
 * @class C1Api
 * @extends {BaseAPI}
 */
export class C1Api extends BaseAPI {
    /**
     * 
     * @summary Get C1 for the authenticated user
     * @param {C1ApiC1ControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1Api
     */
    public c1ControllerFindOne(requestParameters: C1ApiC1ControllerFindOneRequest = {}, options?: AxiosRequestConfig) {
        return C1ApiFp(this.configuration).c1ControllerFindOne(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {C1ApiC1ControllerMembershipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1Api
     */
    public c1ControllerMemberships(requestParameters: C1ApiC1ControllerMembershipsRequest = {}, options?: AxiosRequestConfig) {
        return C1ApiFp(this.configuration).c1ControllerMemberships(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update C1 for the authenticated user
     * @param {C1ApiC1ControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1Api
     */
    public c1ControllerUpdate(requestParameters: C1ApiC1ControllerUpdateRequest, options?: AxiosRequestConfig) {
        return C1ApiFp(this.configuration).c1ControllerUpdate(requestParameters.updateC1Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update aggregation active days info
     * @param {C1ApiC1ControllerUpdateActiveDaysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1Api
     */
    public c1ControllerUpdateActiveDays(requestParameters: C1ApiC1ControllerUpdateActiveDaysRequest, options?: AxiosRequestConfig) {
        return C1ApiFp(this.configuration).c1ControllerUpdateActiveDays(requestParameters.id, requestParameters.updateC1ActiveDays, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update campaign mail quota
     * @param {C1ApiC1ControllerUpdateMailSendQuotaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C1Api
     */
    public c1ControllerUpdateMailSendQuota(requestParameters: C1ApiC1ControllerUpdateMailSendQuotaRequest, options?: AxiosRequestConfig) {
        return C1ApiFp(this.configuration).c1ControllerUpdateMailSendQuota(requestParameters.c1MailSendUpdateDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
