/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthUrlResponseDto } from '../model/';
// @ts-ignore
import { CampaignQueue } from '../model/';
// @ts-ignore
import { CreateCampaignQueueDto } from '../model/';
// @ts-ignore
import { CreateDraftResponseDto } from '../model/';
// @ts-ignore
import { EmailThreadSummaryResponseDto } from '../model/';
// @ts-ignore
import { GenerateTextResponseDto } from '../model/';
// @ts-ignore
import { Inbox } from '../model/';
// @ts-ignore
import { InboxEmailAttachment } from '../model/';
// @ts-ignore
import { InboxEmailMessage } from '../model/';
// @ts-ignore
import { InboxIntentTagsDto } from '../model/';
// @ts-ignore
import { InboxLabelsDto } from '../model/';
// @ts-ignore
import { InboxOAuth2VerifyDto } from '../model/';
// @ts-ignore
import { InboxStatsDto } from '../model/';
// @ts-ignore
import { InboxThreadListDto } from '../model/';
// @ts-ignore
import { InboxUploadUrlDto } from '../model/';
// @ts-ignore
import { NewMessageDto } from '../model/';
/**
 * InboxApi - axios parameter creator
 * @export
 */
export const InboxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCampaignQueueDto} createCampaignQueueDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerCampaign: async (createCampaignQueueDto: CreateCampaignQueueDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCampaignQueueDto' is not null or undefined
            assertParamExists('inboxControllerCampaign', 'createCampaignQueueDto', createCampaignQueueDto)
            const localVarPath = `/api/inbox/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCampaignQueueDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerCreateDraft: async (id: string, newMessageDto: NewMessageDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerCreateDraft', 'id', id)
            // verify required parameter 'newMessageDto' is not null or undefined
            assertParamExists('inboxControllerCreateDraft', 'newMessageDto', newMessageDto)
            const localVarPath = `/api/inbox/{id}/draft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerDeleteMessage: async (id: string, messageId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerDeleteMessage', 'id', id)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('inboxControllerDeleteMessage', 'messageId', messageId)
            const localVarPath = `/api/inbox/{id}/messages/{messageId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerDeleteThread: async (id: string, threadId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerDeleteThread', 'id', id)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('inboxControllerDeleteThread', 'threadId', threadId)
            const localVarPath = `/api/inbox/{id}/threads/{threadId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List inboxes
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/inbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerFindOne', 'id', id)
            const localVarPath = `/api/inbox/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerForwardMessage: async (id: string, messageId: string, newMessageDto: NewMessageDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerForwardMessage', 'id', id)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('inboxControllerForwardMessage', 'messageId', messageId)
            // verify required parameter 'newMessageDto' is not null or undefined
            assertParamExists('inboxControllerForwardMessage', 'newMessageDto', newMessageDto)
            const localVarPath = `/api/inbox/{id}/messages/{messageId}/forward`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGenerateEmailSummaryText: async (id: string, threadId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerGenerateEmailSummaryText', 'id', id)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('inboxControllerGenerateEmailSummaryText', 'threadId', threadId)
            const localVarPath = `/api/inbox/{id}/generate-email-summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (threadId !== undefined) {
                localVarQueryParameter['threadId'] = threadId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {string} recipient 
         * @param {string} sender 
         * @param {string} priority 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGenerateReplyText: async (id: string, threadId: string, messageId: string, recipient: string, sender: string, priority: string, pointers: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerGenerateReplyText', 'id', id)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('inboxControllerGenerateReplyText', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('inboxControllerGenerateReplyText', 'messageId', messageId)
            // verify required parameter 'recipient' is not null or undefined
            assertParamExists('inboxControllerGenerateReplyText', 'recipient', recipient)
            // verify required parameter 'sender' is not null or undefined
            assertParamExists('inboxControllerGenerateReplyText', 'sender', sender)
            // verify required parameter 'priority' is not null or undefined
            assertParamExists('inboxControllerGenerateReplyText', 'priority', priority)
            // verify required parameter 'pointers' is not null or undefined
            assertParamExists('inboxControllerGenerateReplyText', 'pointers', pointers)
            const localVarPath = `/api/inbox/{id}/generate-reply-text`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (threadId !== undefined) {
                localVarQueryParameter['threadId'] = threadId;
            }

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }

            if (recipient !== undefined) {
                localVarQueryParameter['recipient'] = recipient;
            }

            if (sender !== undefined) {
                localVarQueryParameter['sender'] = sender;
            }

            if (priority !== undefined) {
                localVarQueryParameter['priority'] = priority;
            }

            if (pointers !== undefined) {
                localVarQueryParameter['pointers'] = pointers;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} topic 
         * @param {string} recipient 
         * @param {string} sender 
         * @param {string} priority 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGenerateText: async (id: string, topic: string, recipient: string, sender: string, priority: string, pointers: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerGenerateText', 'id', id)
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('inboxControllerGenerateText', 'topic', topic)
            // verify required parameter 'recipient' is not null or undefined
            assertParamExists('inboxControllerGenerateText', 'recipient', recipient)
            // verify required parameter 'sender' is not null or undefined
            assertParamExists('inboxControllerGenerateText', 'sender', sender)
            // verify required parameter 'priority' is not null or undefined
            assertParamExists('inboxControllerGenerateText', 'priority', priority)
            // verify required parameter 'pointers' is not null or undefined
            assertParamExists('inboxControllerGenerateText', 'pointers', pointers)
            const localVarPath = `/api/inbox/{id}/generate-text`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (recipient !== undefined) {
                localVarQueryParameter['recipient'] = recipient;
            }

            if (sender !== undefined) {
                localVarQueryParameter['sender'] = sender;
            }

            if (priority !== undefined) {
                localVarQueryParameter['priority'] = priority;
            }

            if (pointers !== undefined) {
                localVarQueryParameter['pointers'] = pointers;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGetAttachments: async (id: string, messageId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerGetAttachments', 'id', id)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('inboxControllerGetAttachments', 'messageId', messageId)
            const localVarPath = `/api/inbox/{id}/getAttachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} url 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGetPresignedUrl: async (id: string, url: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerGetPresignedUrl', 'id', id)
            // verify required parameter 'url' is not null or undefined
            assertParamExists('inboxControllerGetPresignedUrl', 'url', url)
            const localVarPath = `/api/inbox/{id}/getPresignedUrl`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} fileName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGetUploadUrl: async (id: string, fileName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerGetUploadUrl', 'id', id)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('inboxControllerGetUploadUrl', 'fileName', fileName)
            const localVarPath = `/api/inbox/{id}/getUploadUrl`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGmailAuthUrl: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/inbox/oauth2/gmail/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerInboxIntentTags: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerInboxIntentTags', 'id', id)
            const localVarPath = `/api/inbox/{id}/inbox-intent-tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerInboxLabels: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerInboxLabels', 'id', id)
            const localVarPath = `/api/inbox/{id}/inbox-labels`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerInboxStats: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerInboxStats', 'id', id)
            const localVarPath = `/api/inbox/{id}/stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerJobStatus: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerJobStatus', 'id', id)
            const localVarPath = `/api/inbox/{id}/job-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} provider 
         * @param {InboxOAuth2VerifyDto} inboxOAuth2VerifyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerOauth2Verify: async (provider: string, inboxOAuth2VerifyDto: InboxOAuth2VerifyDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('inboxControllerOauth2Verify', 'provider', provider)
            // verify required parameter 'inboxOAuth2VerifyDto' is not null or undefined
            assertParamExists('inboxControllerOauth2Verify', 'inboxOAuth2VerifyDto', inboxOAuth2VerifyDto)
            const localVarPath = `/api/inbox/oauth2/{provider}/verify`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxOAuth2VerifyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerOutlookAuthUrl: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/inbox/oauth2/outlook/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerReadThread: async (id: string, threadId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerReadThread', 'id', id)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('inboxControllerReadThread', 'threadId', threadId)
            const localVarPath = `/api/inbox/{id}/threads/{threadId}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerRefresh: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerRefresh', 'id', id)
            const localVarPath = `/api/inbox/{id}/refresh`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} attachmentId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerRemoveAttachment: async (id: string, attachmentId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerRemoveAttachment', 'id', id)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('inboxControllerRemoveAttachment', 'attachmentId', attachmentId)
            const localVarPath = `/api/inbox/{id}/removeAttachment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (attachmentId !== undefined) {
                localVarQueryParameter['attachmentId'] = attachmentId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerRemoveInbox: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerRemoveInbox', 'id', id)
            const localVarPath = `/api/inbox/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerReplyMessage: async (id: string, messageId: string, newMessageDto: NewMessageDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerReplyMessage', 'id', id)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('inboxControllerReplyMessage', 'messageId', messageId)
            // verify required parameter 'newMessageDto' is not null or undefined
            assertParamExists('inboxControllerReplyMessage', 'newMessageDto', newMessageDto)
            const localVarPath = `/api/inbox/{id}/messages/{messageId}/reply`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerSendMessage: async (id: string, newMessageDto: NewMessageDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerSendMessage', 'id', id)
            // verify required parameter 'newMessageDto' is not null or undefined
            assertParamExists('inboxControllerSendMessage', 'newMessageDto', newMessageDto)
            const localVarPath = `/api/inbox/{id}/send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerSync: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerSync', 'id', id)
            const localVarPath = `/api/inbox/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} labelId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerThreadDetails: async (id: string, threadId: string, labelId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerThreadDetails', 'id', id)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('inboxControllerThreadDetails', 'threadId', threadId)
            // verify required parameter 'labelId' is not null or undefined
            assertParamExists('inboxControllerThreadDetails', 'labelId', labelId)
            const localVarPath = `/api/inbox/{id}/threads/{threadId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (labelId !== undefined) {
                localVarQueryParameter['labelId'] = labelId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerThreadIntentTags: async (id: string, threadId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerThreadIntentTags', 'id', id)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('inboxControllerThreadIntentTags', 'threadId', threadId)
            const localVarPath = `/api/inbox/{id}/threads/{threadId}/intent-tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} label 
         * @param {number} page 
         * @param {string} tag 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerThreadList: async (id: string, label: string, page: number, tag: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerThreadList', 'id', id)
            // verify required parameter 'label' is not null or undefined
            assertParamExists('inboxControllerThreadList', 'label', label)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('inboxControllerThreadList', 'page', page)
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('inboxControllerThreadList', 'tag', tag)
            const localVarPath = `/api/inbox/{id}/threads`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerUpdateDraft: async (id: string, newMessageDto: NewMessageDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerUpdateDraft', 'id', id)
            // verify required parameter 'newMessageDto' is not null or undefined
            assertParamExists('inboxControllerUpdateDraft', 'newMessageDto', newMessageDto)
            const localVarPath = `/api/inbox/{id}/draft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} action 
         * @param {string} [xTenantId] 
         * @param {string} [messageId] 
         * @param {string} [threadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerUpdateLabel: async (id: string, action: string, xTenantId?: string, messageId?: string, threadId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerUpdateLabel', 'id', id)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('inboxControllerUpdateLabel', 'action', action)
            const localVarPath = `/api/inbox/{id}/updateLabel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }

            if (threadId !== undefined) {
                localVarQueryParameter['threadId'] = threadId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerUpdateReadStatus: async (id: string, threadId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inboxControllerUpdateReadStatus', 'id', id)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('inboxControllerUpdateReadStatus', 'threadId', threadId)
            const localVarPath = `/api/inbox/{id}/threads/{threadId}/update-read-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxApi - functional programming interface
 * @export
 */
export const InboxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCampaignQueueDto} createCampaignQueueDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerCampaign(createCampaignQueueDto: CreateCampaignQueueDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignQueue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerCampaign(createCampaignQueueDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerCreateDraft(id: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDraftResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerCreateDraft(id, newMessageDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerDeleteMessage(id: string, messageId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxEmailMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerDeleteMessage(id, messageId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerDeleteThread(id: string, threadId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerDeleteThread(id, threadId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List inboxes
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Inbox>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inbox>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerForwardMessage(id: string, messageId: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerForwardMessage(id, messageId, newMessageDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerGenerateEmailSummaryText(id: string, threadId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailThreadSummaryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerGenerateEmailSummaryText(id, threadId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {string} recipient 
         * @param {string} sender 
         * @param {string} priority 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerGenerateReplyText(id: string, threadId: string, messageId: string, recipient: string, sender: string, priority: string, pointers: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTextResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerGenerateReplyText(id, threadId, messageId, recipient, sender, priority, pointers, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} topic 
         * @param {string} recipient 
         * @param {string} sender 
         * @param {string} priority 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerGenerateText(id: string, topic: string, recipient: string, sender: string, priority: string, pointers: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTextResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerGenerateText(id, topic, recipient, sender, priority, pointers, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerGetAttachments(id: string, messageId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InboxEmailAttachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerGetAttachments(id, messageId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} url 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerGetPresignedUrl(id: string, url: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerGetPresignedUrl(id, url, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} fileName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerGetUploadUrl(id: string, fileName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxUploadUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerGetUploadUrl(id, fileName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerGmailAuthUrl(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUrlResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerGmailAuthUrl(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerInboxIntentTags(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InboxIntentTagsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerInboxIntentTags(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerInboxLabels(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InboxLabelsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerInboxLabels(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerInboxStats(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerInboxStats(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerJobStatus(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerJobStatus(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} provider 
         * @param {InboxOAuth2VerifyDto} inboxOAuth2VerifyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerOauth2Verify(provider: string, inboxOAuth2VerifyDto: InboxOAuth2VerifyDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inbox>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerOauth2Verify(provider, inboxOAuth2VerifyDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerOutlookAuthUrl(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUrlResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerOutlookAuthUrl(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerReadThread(id: string, threadId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerReadThread(id, threadId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerRefresh(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerRefresh(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} attachmentId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerRemoveAttachment(id: string, attachmentId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerRemoveAttachment(id, attachmentId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerRemoveInbox(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerRemoveInbox(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerReplyMessage(id: string, messageId: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerReplyMessage(id, messageId, newMessageDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerSendMessage(id: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerSendMessage(id, newMessageDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerSync(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerSync(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} labelId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerThreadDetails(id: string, threadId: string, labelId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InboxEmailMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerThreadDetails(id, threadId, labelId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerThreadIntentTags(id: string, threadId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerThreadIntentTags(id, threadId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} label 
         * @param {number} page 
         * @param {string} tag 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerThreadList(id: string, label: string, page: number, tag: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxThreadListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerThreadList(id, label, page, tag, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerUpdateDraft(id: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDraftResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerUpdateDraft(id, newMessageDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} action 
         * @param {string} [xTenantId] 
         * @param {string} [messageId] 
         * @param {string} [threadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerUpdateLabel(id: string, action: string, xTenantId?: string, messageId?: string, threadId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerUpdateLabel(id, action, xTenantId, messageId, threadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboxControllerUpdateReadStatus(id: string, threadId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboxControllerUpdateReadStatus(id, threadId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InboxApi - factory interface
 * @export
 */
export const InboxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCampaignQueueDto} createCampaignQueueDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerCampaign(createCampaignQueueDto: CreateCampaignQueueDto, xTenantId?: string, options?: any): AxiosPromise<CampaignQueue> {
            return localVarFp.inboxControllerCampaign(createCampaignQueueDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerCreateDraft(id: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: any): AxiosPromise<CreateDraftResponseDto> {
            return localVarFp.inboxControllerCreateDraft(id, newMessageDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerDeleteMessage(id: string, messageId: string, xTenantId?: string, options?: any): AxiosPromise<InboxEmailMessage> {
            return localVarFp.inboxControllerDeleteMessage(id, messageId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerDeleteThread(id: string, threadId: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerDeleteThread(id, threadId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List inboxes
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<Inbox>> {
            return localVarFp.inboxControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<Inbox> {
            return localVarFp.inboxControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerForwardMessage(id: string, messageId: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerForwardMessage(id, messageId, newMessageDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGenerateEmailSummaryText(id: string, threadId: string, xTenantId?: string, options?: any): AxiosPromise<EmailThreadSummaryResponseDto> {
            return localVarFp.inboxControllerGenerateEmailSummaryText(id, threadId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {string} recipient 
         * @param {string} sender 
         * @param {string} priority 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGenerateReplyText(id: string, threadId: string, messageId: string, recipient: string, sender: string, priority: string, pointers: string, xTenantId?: string, options?: any): AxiosPromise<GenerateTextResponseDto> {
            return localVarFp.inboxControllerGenerateReplyText(id, threadId, messageId, recipient, sender, priority, pointers, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} topic 
         * @param {string} recipient 
         * @param {string} sender 
         * @param {string} priority 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGenerateText(id: string, topic: string, recipient: string, sender: string, priority: string, pointers: string, xTenantId?: string, options?: any): AxiosPromise<GenerateTextResponseDto> {
            return localVarFp.inboxControllerGenerateText(id, topic, recipient, sender, priority, pointers, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGetAttachments(id: string, messageId: string, xTenantId?: string, options?: any): AxiosPromise<Array<InboxEmailAttachment>> {
            return localVarFp.inboxControllerGetAttachments(id, messageId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} url 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGetPresignedUrl(id: string, url: string, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.inboxControllerGetPresignedUrl(id, url, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} fileName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGetUploadUrl(id: string, fileName: string, xTenantId?: string, options?: any): AxiosPromise<InboxUploadUrlDto> {
            return localVarFp.inboxControllerGetUploadUrl(id, fileName, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerGmailAuthUrl(xTenantId?: string, options?: any): AxiosPromise<AuthUrlResponseDto> {
            return localVarFp.inboxControllerGmailAuthUrl(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerInboxIntentTags(id: string, xTenantId?: string, options?: any): AxiosPromise<Array<InboxIntentTagsDto>> {
            return localVarFp.inboxControllerInboxIntentTags(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerInboxLabels(id: string, xTenantId?: string, options?: any): AxiosPromise<Array<InboxLabelsDto>> {
            return localVarFp.inboxControllerInboxLabels(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerInboxStats(id: string, xTenantId?: string, options?: any): AxiosPromise<InboxStatsDto> {
            return localVarFp.inboxControllerInboxStats(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerJobStatus(id: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerJobStatus(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} provider 
         * @param {InboxOAuth2VerifyDto} inboxOAuth2VerifyDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerOauth2Verify(provider: string, inboxOAuth2VerifyDto: InboxOAuth2VerifyDto, xTenantId?: string, options?: any): AxiosPromise<Inbox> {
            return localVarFp.inboxControllerOauth2Verify(provider, inboxOAuth2VerifyDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerOutlookAuthUrl(xTenantId?: string, options?: any): AxiosPromise<AuthUrlResponseDto> {
            return localVarFp.inboxControllerOutlookAuthUrl(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerReadThread(id: string, threadId: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerReadThread(id, threadId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerRefresh(id: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerRefresh(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} attachmentId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerRemoveAttachment(id: string, attachmentId: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerRemoveAttachment(id, attachmentId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerRemoveInbox(id: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerRemoveInbox(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} messageId 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerReplyMessage(id: string, messageId: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerReplyMessage(id, messageId, newMessageDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerSendMessage(id: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerSendMessage(id, newMessageDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerSync(id: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerSync(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} labelId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerThreadDetails(id: string, threadId: string, labelId: string, xTenantId?: string, options?: any): AxiosPromise<Array<InboxEmailMessage>> {
            return localVarFp.inboxControllerThreadDetails(id, threadId, labelId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerThreadIntentTags(id: string, threadId: string, xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.inboxControllerThreadIntentTags(id, threadId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} label 
         * @param {number} page 
         * @param {string} tag 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerThreadList(id: string, label: string, page: number, tag: string, xTenantId?: string, options?: any): AxiosPromise<InboxThreadListDto> {
            return localVarFp.inboxControllerThreadList(id, label, page, tag, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {NewMessageDto} newMessageDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerUpdateDraft(id: string, newMessageDto: NewMessageDto, xTenantId?: string, options?: any): AxiosPromise<CreateDraftResponseDto> {
            return localVarFp.inboxControllerUpdateDraft(id, newMessageDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} action 
         * @param {string} [xTenantId] 
         * @param {string} [messageId] 
         * @param {string} [threadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerUpdateLabel(id: string, action: string, xTenantId?: string, messageId?: string, threadId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerUpdateLabel(id, action, xTenantId, messageId, threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} threadId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboxControllerUpdateReadStatus(id: string, threadId: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inboxControllerUpdateReadStatus(id, threadId, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for inboxControllerCampaign operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerCampaignRequest
 */
export interface InboxApiInboxControllerCampaignRequest {
    /**
     * 
     * @type {CreateCampaignQueueDto}
     * @memberof InboxApiInboxControllerCampaign
     */
    readonly createCampaignQueueDto: CreateCampaignQueueDto

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerCampaign
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerCreateDraft operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerCreateDraftRequest
 */
export interface InboxApiInboxControllerCreateDraftRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerCreateDraft
     */
    readonly id: string

    /**
     * 
     * @type {NewMessageDto}
     * @memberof InboxApiInboxControllerCreateDraft
     */
    readonly newMessageDto: NewMessageDto

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerCreateDraft
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerDeleteMessage operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerDeleteMessageRequest
 */
export interface InboxApiInboxControllerDeleteMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerDeleteMessage
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerDeleteMessage
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerDeleteMessage
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerDeleteThread operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerDeleteThreadRequest
 */
export interface InboxApiInboxControllerDeleteThreadRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerDeleteThread
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerDeleteThread
     */
    readonly threadId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerDeleteThread
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerFindAll operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerFindAllRequest
 */
export interface InboxApiInboxControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerFindOne operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerFindOneRequest
 */
export interface InboxApiInboxControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerForwardMessage operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerForwardMessageRequest
 */
export interface InboxApiInboxControllerForwardMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerForwardMessage
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerForwardMessage
     */
    readonly messageId: string

    /**
     * 
     * @type {NewMessageDto}
     * @memberof InboxApiInboxControllerForwardMessage
     */
    readonly newMessageDto: NewMessageDto

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerForwardMessage
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerGenerateEmailSummaryText operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerGenerateEmailSummaryTextRequest
 */
export interface InboxApiInboxControllerGenerateEmailSummaryTextRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateEmailSummaryText
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateEmailSummaryText
     */
    readonly threadId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateEmailSummaryText
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerGenerateReplyText operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerGenerateReplyTextRequest
 */
export interface InboxApiInboxControllerGenerateReplyTextRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateReplyText
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateReplyText
     */
    readonly threadId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateReplyText
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateReplyText
     */
    readonly recipient: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateReplyText
     */
    readonly sender: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateReplyText
     */
    readonly priority: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateReplyText
     */
    readonly pointers: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateReplyText
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerGenerateText operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerGenerateTextRequest
 */
export interface InboxApiInboxControllerGenerateTextRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateText
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateText
     */
    readonly topic: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateText
     */
    readonly recipient: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateText
     */
    readonly sender: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateText
     */
    readonly priority: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateText
     */
    readonly pointers: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGenerateText
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerGetAttachments operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerGetAttachmentsRequest
 */
export interface InboxApiInboxControllerGetAttachmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGetAttachments
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGetAttachments
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGetAttachments
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerGetPresignedUrl operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerGetPresignedUrlRequest
 */
export interface InboxApiInboxControllerGetPresignedUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGetPresignedUrl
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGetPresignedUrl
     */
    readonly url: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGetPresignedUrl
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerGetUploadUrl operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerGetUploadUrlRequest
 */
export interface InboxApiInboxControllerGetUploadUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGetUploadUrl
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGetUploadUrl
     */
    readonly fileName: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGetUploadUrl
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerGmailAuthUrl operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerGmailAuthUrlRequest
 */
export interface InboxApiInboxControllerGmailAuthUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerGmailAuthUrl
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerInboxIntentTags operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerInboxIntentTagsRequest
 */
export interface InboxApiInboxControllerInboxIntentTagsRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerInboxIntentTags
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerInboxIntentTags
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerInboxLabels operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerInboxLabelsRequest
 */
export interface InboxApiInboxControllerInboxLabelsRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerInboxLabels
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerInboxLabels
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerInboxStats operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerInboxStatsRequest
 */
export interface InboxApiInboxControllerInboxStatsRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerInboxStats
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerInboxStats
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerJobStatus operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerJobStatusRequest
 */
export interface InboxApiInboxControllerJobStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerJobStatus
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerJobStatus
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerOauth2Verify operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerOauth2VerifyRequest
 */
export interface InboxApiInboxControllerOauth2VerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerOauth2Verify
     */
    readonly provider: string

    /**
     * 
     * @type {InboxOAuth2VerifyDto}
     * @memberof InboxApiInboxControllerOauth2Verify
     */
    readonly inboxOAuth2VerifyDto: InboxOAuth2VerifyDto

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerOauth2Verify
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerOutlookAuthUrl operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerOutlookAuthUrlRequest
 */
export interface InboxApiInboxControllerOutlookAuthUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerOutlookAuthUrl
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerReadThread operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerReadThreadRequest
 */
export interface InboxApiInboxControllerReadThreadRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerReadThread
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerReadThread
     */
    readonly threadId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerReadThread
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerRefresh operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerRefreshRequest
 */
export interface InboxApiInboxControllerRefreshRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerRefresh
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerRefresh
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerRemoveAttachment operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerRemoveAttachmentRequest
 */
export interface InboxApiInboxControllerRemoveAttachmentRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerRemoveAttachment
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerRemoveAttachment
     */
    readonly attachmentId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerRemoveAttachment
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerRemoveInbox operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerRemoveInboxRequest
 */
export interface InboxApiInboxControllerRemoveInboxRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerRemoveInbox
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerRemoveInbox
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerReplyMessage operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerReplyMessageRequest
 */
export interface InboxApiInboxControllerReplyMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerReplyMessage
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerReplyMessage
     */
    readonly messageId: string

    /**
     * 
     * @type {NewMessageDto}
     * @memberof InboxApiInboxControllerReplyMessage
     */
    readonly newMessageDto: NewMessageDto

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerReplyMessage
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerSendMessage operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerSendMessageRequest
 */
export interface InboxApiInboxControllerSendMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerSendMessage
     */
    readonly id: string

    /**
     * 
     * @type {NewMessageDto}
     * @memberof InboxApiInboxControllerSendMessage
     */
    readonly newMessageDto: NewMessageDto

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerSendMessage
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerSync operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerSyncRequest
 */
export interface InboxApiInboxControllerSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerSync
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerSync
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerThreadDetails operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerThreadDetailsRequest
 */
export interface InboxApiInboxControllerThreadDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadDetails
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadDetails
     */
    readonly threadId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadDetails
     */
    readonly labelId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadDetails
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerThreadIntentTags operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerThreadIntentTagsRequest
 */
export interface InboxApiInboxControllerThreadIntentTagsRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadIntentTags
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadIntentTags
     */
    readonly threadId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadIntentTags
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerThreadList operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerThreadListRequest
 */
export interface InboxApiInboxControllerThreadListRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadList
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadList
     */
    readonly label: string

    /**
     * 
     * @type {number}
     * @memberof InboxApiInboxControllerThreadList
     */
    readonly page: number

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadList
     */
    readonly tag: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerThreadList
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerUpdateDraft operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerUpdateDraftRequest
 */
export interface InboxApiInboxControllerUpdateDraftRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateDraft
     */
    readonly id: string

    /**
     * 
     * @type {NewMessageDto}
     * @memberof InboxApiInboxControllerUpdateDraft
     */
    readonly newMessageDto: NewMessageDto

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateDraft
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for inboxControllerUpdateLabel operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerUpdateLabelRequest
 */
export interface InboxApiInboxControllerUpdateLabelRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateLabel
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateLabel
     */
    readonly action: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateLabel
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateLabel
     */
    readonly messageId?: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateLabel
     */
    readonly threadId?: string
}

/**
 * Request parameters for inboxControllerUpdateReadStatus operation in InboxApi.
 * @export
 * @interface InboxApiInboxControllerUpdateReadStatusRequest
 */
export interface InboxApiInboxControllerUpdateReadStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateReadStatus
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateReadStatus
     */
    readonly threadId: string

    /**
     * 
     * @type {string}
     * @memberof InboxApiInboxControllerUpdateReadStatus
     */
    readonly xTenantId?: string
}

/**
 * InboxApi - object-oriented interface
 * @export
 * @class InboxApi
 * @extends {BaseAPI}
 */
export class InboxApi extends BaseAPI {
    /**
     * 
     * @param {InboxApiInboxControllerCampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerCampaign(requestParameters: InboxApiInboxControllerCampaignRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerCampaign(requestParameters.createCampaignQueueDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerCreateDraftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerCreateDraft(requestParameters: InboxApiInboxControllerCreateDraftRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerCreateDraft(requestParameters.id, requestParameters.newMessageDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerDeleteMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerDeleteMessage(requestParameters: InboxApiInboxControllerDeleteMessageRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerDeleteMessage(requestParameters.id, requestParameters.messageId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerDeleteThreadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerDeleteThread(requestParameters: InboxApiInboxControllerDeleteThreadRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerDeleteThread(requestParameters.id, requestParameters.threadId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List inboxes
     * @param {InboxApiInboxControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerFindAll(requestParameters: InboxApiInboxControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerFindOne(requestParameters: InboxApiInboxControllerFindOneRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerForwardMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerForwardMessage(requestParameters: InboxApiInboxControllerForwardMessageRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerForwardMessage(requestParameters.id, requestParameters.messageId, requestParameters.newMessageDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerGenerateEmailSummaryTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerGenerateEmailSummaryText(requestParameters: InboxApiInboxControllerGenerateEmailSummaryTextRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerGenerateEmailSummaryText(requestParameters.id, requestParameters.threadId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerGenerateReplyTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerGenerateReplyText(requestParameters: InboxApiInboxControllerGenerateReplyTextRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerGenerateReplyText(requestParameters.id, requestParameters.threadId, requestParameters.messageId, requestParameters.recipient, requestParameters.sender, requestParameters.priority, requestParameters.pointers, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerGenerateTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerGenerateText(requestParameters: InboxApiInboxControllerGenerateTextRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerGenerateText(requestParameters.id, requestParameters.topic, requestParameters.recipient, requestParameters.sender, requestParameters.priority, requestParameters.pointers, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerGetAttachmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerGetAttachments(requestParameters: InboxApiInboxControllerGetAttachmentsRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerGetAttachments(requestParameters.id, requestParameters.messageId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerGetPresignedUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerGetPresignedUrl(requestParameters: InboxApiInboxControllerGetPresignedUrlRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerGetPresignedUrl(requestParameters.id, requestParameters.url, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerGetUploadUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerGetUploadUrl(requestParameters: InboxApiInboxControllerGetUploadUrlRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerGetUploadUrl(requestParameters.id, requestParameters.fileName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerGmailAuthUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerGmailAuthUrl(requestParameters: InboxApiInboxControllerGmailAuthUrlRequest = {}, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerGmailAuthUrl(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerInboxIntentTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerInboxIntentTags(requestParameters: InboxApiInboxControllerInboxIntentTagsRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerInboxIntentTags(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerInboxLabelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerInboxLabels(requestParameters: InboxApiInboxControllerInboxLabelsRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerInboxLabels(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerInboxStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerInboxStats(requestParameters: InboxApiInboxControllerInboxStatsRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerInboxStats(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerJobStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerJobStatus(requestParameters: InboxApiInboxControllerJobStatusRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerJobStatus(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerOauth2VerifyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerOauth2Verify(requestParameters: InboxApiInboxControllerOauth2VerifyRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerOauth2Verify(requestParameters.provider, requestParameters.inboxOAuth2VerifyDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerOutlookAuthUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerOutlookAuthUrl(requestParameters: InboxApiInboxControllerOutlookAuthUrlRequest = {}, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerOutlookAuthUrl(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerReadThreadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerReadThread(requestParameters: InboxApiInboxControllerReadThreadRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerReadThread(requestParameters.id, requestParameters.threadId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerRefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerRefresh(requestParameters: InboxApiInboxControllerRefreshRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerRefresh(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerRemoveAttachmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerRemoveAttachment(requestParameters: InboxApiInboxControllerRemoveAttachmentRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerRemoveAttachment(requestParameters.id, requestParameters.attachmentId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerRemoveInboxRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerRemoveInbox(requestParameters: InboxApiInboxControllerRemoveInboxRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerRemoveInbox(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerReplyMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerReplyMessage(requestParameters: InboxApiInboxControllerReplyMessageRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerReplyMessage(requestParameters.id, requestParameters.messageId, requestParameters.newMessageDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerSendMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerSendMessage(requestParameters: InboxApiInboxControllerSendMessageRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerSendMessage(requestParameters.id, requestParameters.newMessageDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerSyncRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerSync(requestParameters: InboxApiInboxControllerSyncRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerSync(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerThreadDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerThreadDetails(requestParameters: InboxApiInboxControllerThreadDetailsRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerThreadDetails(requestParameters.id, requestParameters.threadId, requestParameters.labelId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerThreadIntentTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerThreadIntentTags(requestParameters: InboxApiInboxControllerThreadIntentTagsRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerThreadIntentTags(requestParameters.id, requestParameters.threadId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerThreadListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerThreadList(requestParameters: InboxApiInboxControllerThreadListRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerThreadList(requestParameters.id, requestParameters.label, requestParameters.page, requestParameters.tag, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerUpdateDraftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerUpdateDraft(requestParameters: InboxApiInboxControllerUpdateDraftRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerUpdateDraft(requestParameters.id, requestParameters.newMessageDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerUpdateLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerUpdateLabel(requestParameters: InboxApiInboxControllerUpdateLabelRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerUpdateLabel(requestParameters.id, requestParameters.action, requestParameters.xTenantId, requestParameters.messageId, requestParameters.threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboxApiInboxControllerUpdateReadStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public inboxControllerUpdateReadStatus(requestParameters: InboxApiInboxControllerUpdateReadStatusRequest, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).inboxControllerUpdateReadStatus(requestParameters.id, requestParameters.threadId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
