import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { IDate } from '@/types/date.type';
import { DateInfo, DateTypeEnum } from '@/api/client';
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  getMonth,
  getQuarter,
  getWeek,
  getYear,
  isSameDay,
  isSunday,
} from 'date-fns';
import { useDateFormatter } from '@/composables/useDateFormatter';
import { useAccountStore } from './account';

export const useDateStore = defineStore('date', () => {
  const iDate = ref<IDate>();
  const dateInfo = ref<DateInfo>();
  const iSurveysOverviewDate = ref<IDate>();
  const surveysOverviewDateInfo = ref<DateInfo>();
  const { getIDateDayObj, getIDateWeekObj, getIDateMonthObj, getIDateQuarterObj, getIDateYearObj, getWeekNo } =
    useDateFormatter();

  const _accountStore = useAccountStore();

  // const { onboardingCompletedAt } = storeToRefs(useAccountStore());

  function initialize() {
    const isExists = iDate.value;
    if (isExists) {
      return;
    }
    const currentDate = endOfDay(addDays(new Date(), -1));
    let value = getIDateDayObj(currentDate);
    if (isSunday(currentDate)) {
      value = getIDateWeekObj(currentDate);
    }
    updateDateSelection(value);
  }

  function initializeSurveysOverviewDate() {
    const isExists = iSurveysOverviewDate.value;
    if (isExists) {
      return;
    }
    const currentDate = endOfDay(new Date());
    let value = getIDateDayObj(currentDate);
    if (isSunday(currentDate)) {
      value = getIDateWeekObj(currentDate);
    }
    updateSurveysOverviewDateSelection(value);
  }

  const isLatestAggregation = computed(() => {
    const endDate = iDate.value?.endDate;
    if (!endDate) {
      return true;
    }
    // let tempDate: IDate | undefined;
    const currentDate = new Date();
    switch (iDate.value?.type) {
      case DateTypeEnum.Day:
      case DateTypeEnum.Week: {
        const nextDate = addDays(endDate, 1);
        if (isSameDay(currentDate, nextDate)) {
          return true;
        } else {
          return false;
        }
      }
      case DateTypeEnum.Month:
      case DateTypeEnum.Quarter:
      case DateTypeEnum.Year:
        return false;
      // case DateTypeEnum.Week:
      //   tempDate = getIDateWeekObj(addWeeks(endDate, 1));
      //   break;
      // case DateTypeEnum.Month:
      //   tempDate = getIDateMonthObj(addMonths(endDate, 1));
      //   break;
      // case DateTypeEnum.Quarter:
      //   tempDate = getIDateQuarterObj(addQuarters(endDate, 1));
      //   break;
      // case DateTypeEnum.Year:
      //   tempDate = getIDateYearObj(addYears(endDate, 1));
      //   break;
      default:
        return false;
    }
  });

  function setup(dateType: string, sDate: string, dateText: string) {
    const dType = DateTypeEnum[dateType as keyof typeof DateTypeEnum];
    if (!dType || !sDate || !dateText) {
      return;
    }
    populateIDate(dType, sDate, dateText);
  }

  function populateIDate(type: DateTypeEnum, sDate: string, dateText: string) {
    const startDate = new Date(sDate);
    let endDate: Date = startDate;
    switch (type) {
      case DateTypeEnum.Day:
        endDate = endOfDay(startDate);
        break;
      case DateTypeEnum.Week:
        endDate = endOfWeek(startDate, { weekStartsOn: 1 });
        break;
      case DateTypeEnum.Month:
        endDate = endOfMonth(startDate);
        break;
      case DateTypeEnum.Quarter:
        endDate = endOfQuarter(startDate);
        break;
      case DateTypeEnum.Year:
        endDate = endOfYear(startDate);
        break;
    }
    const iDate = {
      type,
      startDate: startDate,
      endDate: endDate,
      text: dateText,
      isDisabled: false,
    } as IDate;
    updateDateSelection(iDate);
  }

  function updateDateSelection(date: IDate) {
    iDate.value = date;
    dateInfo.value = {
      type: date.type,
      dateUtc: format(date.endDate, "yyyy/MM/dd'T'HH:mm:ss'Z'"),
      date: format(date.endDate, 'yyyy/MM/dd'),
    } as DateInfo;
  }

  function updateSurveysOverviewDateSelection(date: IDate) {
    iSurveysOverviewDate.value = date;
    surveysOverviewDateInfo.value = {
      type: date.type,
      dateUtc: format(date.endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      date: format(date.endDate, 'yyyy-MM-dd'),
    } as DateInfo;
  }

  const dateText = computed(() => {
    return iDate.value?.text || '';
  });

  const surveysOverviewDateText = computed(() => {
    return iSurveysOverviewDate.value?.text || '';
  });

  function getDateLabel(dateString: string): string {
    const date = new Date(dateString);
    switch (iDate.value?.type) {
      case DateTypeEnum.Day:
        return `${format(date, 'EEE')}-${format(date, 'do')} ${format(date, 'MMM')}`;
      case DateTypeEnum.Week: {
        const weekNo = getWeekNo(date);
        return `W${weekNo}`;
      }
      case DateTypeEnum.Month:
        return format(date, 'MMM');
      case DateTypeEnum.Quarter: {
        const quarterNo = getQuarter(date);
        return `Q-${quarterNo}`;
      }
      case DateTypeEnum.Year:
        // return '';
        return format(date, 'MMM');
    }
    return '';
  }

  function getSurveysOverviewDateLabel(dateString: string): string {
    const date = new Date(dateString);
    switch (iSurveysOverviewDate.value?.type) {
      case DateTypeEnum.Day:
        return `${format(date, 'EEE')}-${format(date, 'do')} ${format(date, 'MMM')}`;
      case DateTypeEnum.Week: {
        const weekNo = getWeekNo(date);
        return `W${weekNo}`;
      }
      case DateTypeEnum.Month:
        return format(date, 'MMM');
      case DateTypeEnum.Quarter: {
        const quarterNo = getQuarter(date);
        return `Q-${quarterNo}`;
      }
      case DateTypeEnum.Year:
        // return '';
        return format(date, 'MMM');
    }
    return '';
  }

  function setupDateFromQueryDateString(dateString: string, dateType: DateTypeEnum) {
    const currentDate = endOfDay(new Date(dateString));
    let value = {} as IDate;
    switch (dateType) {
      case DateTypeEnum.Week:
        value = getIDateWeekObj(currentDate);
        break;
      case DateTypeEnum.Month:
        value = getIDateMonthObj(currentDate);
        break;
      case DateTypeEnum.Quarter:
        value = getIDateQuarterObj(currentDate);
        break;
      case DateTypeEnum.Day:
        value = getIDateDayObj(currentDate);
        break;
      case DateTypeEnum.Year:
        value = getIDateYearObj(currentDate);
        break;
    }
    updateDateSelection(value);
  }

  function setupSurveysOverviewDateFromQueryDateString(dateString: string, dateType: DateTypeEnum) {
    const currentDate = endOfDay(new Date(dateString));
    let value = {} as IDate;
    switch (dateType) {
      case DateTypeEnum.Week:
        value = getIDateWeekObj(currentDate);
        break;
      case DateTypeEnum.Month:
        value = getIDateMonthObj(currentDate);
        break;
      case DateTypeEnum.Quarter:
        value = getIDateQuarterObj(currentDate);
        break;
      case DateTypeEnum.Day:
        value = getIDateDayObj(currentDate);
        break;
      case DateTypeEnum.Year:
        value = getIDateYearObj(currentDate);
        break;
    }
    updateSurveysOverviewDateSelection(value);
  }

  function previousDate(date?: IDate) {
    if (!date) {
      return undefined;
    }
    const endDate = date.endDate;
    let tempDate: IDate | undefined;
    switch (date.type) {
      case DateTypeEnum.Day:
        {
          const previousDate = addDays(endDate, -1);
          // if (isSunday(previousDate)) {
          //   previousDate = addDays(previousDate, -2);
          // }
          // if (isSaturday(previousDate)) {
          //   previousDate = addDays(previousDate, -1);
          // }
          tempDate = getIDateDayObj(previousDate);
        }
        break;
      case DateTypeEnum.Week:
        tempDate = getIDateWeekObj(addWeeks(endDate, -1));
        break;
      case DateTypeEnum.Month:
        tempDate = getIDateMonthObj(addMonths(endDate, -1));
        break;
      case DateTypeEnum.Quarter:
        tempDate = getIDateQuarterObj(addQuarters(endDate, -1));
        break;
      case DateTypeEnum.Year:
        tempDate = getIDateYearObj(addYears(endDate, -1));
        break;
    }
    if (tempDate && tempDate?.endDate < _accountStore.onboardingCompletedAt) {
      return undefined;
    }
    return tempDate;
  }

  function nextDate(date?: IDate) {
    if (!date) {
      return undefined;
    }
    const endDate = date.endDate;
    let tempDate: IDate | undefined;
    switch (date.type) {
      case DateTypeEnum.Day:
        {
          const nextDate = addDays(endDate, 1);
          // if (isSaturday(nextDate)) {
          //   nextDate = addDays(nextDate, 2);
          // }
          // if (isSunday(nextDate)) {
          //   nextDate = addDays(nextDate, 1);
          // }
          tempDate = getIDateDayObj(nextDate);
        }
        break;
      case DateTypeEnum.Week:
        tempDate = getIDateWeekObj(addWeeks(endDate, 1));
        break;
      case DateTypeEnum.Month:
        tempDate = getIDateMonthObj(addMonths(endDate, 1));
        break;
      case DateTypeEnum.Quarter:
        tempDate = getIDateQuarterObj(addQuarters(endDate, 1));
        break;
      case DateTypeEnum.Year:
        tempDate = getIDateYearObj(addYears(endDate, 1));
        break;
    }
    const currentDate = new Date();
    if (tempDate && tempDate?.endDate > currentDate) {
      return undefined;
    }
    return tempDate;
  }

  function previousSurveysOverviewDate(date?: IDate) {
    if (!date) {
      return undefined;
    }
    const endDate = date.endDate;
    let tempDate: IDate | undefined;
    switch (date.type) {
      case DateTypeEnum.Day:
        {
          const previousDate = addDays(endDate, -1);
          tempDate = getIDateDayObj(previousDate);
        }
        break;
      case DateTypeEnum.Week:
        tempDate = getIDateWeekObj(addWeeks(endDate, -1));
        break;
      case DateTypeEnum.Month:
        tempDate = getIDateMonthObj(addMonths(endDate, -1));
        break;
      case DateTypeEnum.Quarter:
        tempDate = getIDateQuarterObj(addQuarters(endDate, -1));
        break;
      case DateTypeEnum.Year:
        tempDate = getIDateYearObj(addYears(endDate, -1));
        break;
    }
    if (tempDate && tempDate?.endDate < _accountStore.onboardingCompletedAt) {
      return undefined;
    }
    return tempDate;
  }

  function nextSurveysOverviewDate(date?: IDate) {
    if (!date) {
      return undefined;
    }
    const currentDate = new Date();
    const endDate = date.endDate;
    let tempDate: IDate | undefined;
    switch (date.type) {
      case DateTypeEnum.Day:
        tempDate = getIDateDayObj(addDays(endDate, 1));
        if (tempDate && tempDate?.endDate > endOfDay(currentDate)) {
          return undefined;
        }
        break;
      case DateTypeEnum.Week:
        tempDate = getIDateWeekObj(addWeeks(endDate, 1));
        if (tempDate && getWeek(tempDate.startDate) > getWeek(currentDate)) {
          return undefined;
        }
        break;
      case DateTypeEnum.Month:
        tempDate = getIDateMonthObj(addMonths(endDate, 1));
        if (tempDate && getMonth(tempDate.endDate) > getMonth(currentDate)) {
          return undefined;
        }
        break;
      case DateTypeEnum.Quarter:
        tempDate = getIDateQuarterObj(addQuarters(endDate, 1));
        if (
          tempDate &&
          (getYear(tempDate?.endDate) > getYear(currentDate) ||
            getQuarter(tempDate.startDate) > getQuarter(currentDate))
        ) {
          return undefined;
        }
        break;
      case DateTypeEnum.Year:
        tempDate = getIDateYearObj(addYears(endDate, 1));
        if (tempDate && getYear(tempDate?.endDate) > getYear(currentDate)) {
          return undefined;
        }
        break;
    }
    return tempDate;
  }

  function getCustomerMovementTrendDateLabel(dateString: string, showAggregation: boolean): string {
    if (!showAggregation) return getDateLabel(dateString);

    const date = new Date(dateString);
    switch (iDate.value?.type) {
      case DateTypeEnum.Day:
      case DateTypeEnum.Week:
      case DateTypeEnum.Month:
        return format(date, 'MMM');
      case DateTypeEnum.Quarter: {
        const quarterNo = getQuarter(date);
        return `Q-${quarterNo}`;
      }
      case DateTypeEnum.Year:
        return '';
    }
    return '';
  }

  return {
    dateInfo,
    iDate,
    surveysOverviewDateInfo,
    iSurveysOverviewDate,
    initialize,
    initializeSurveysOverviewDate,
    setup,
    updateDateSelection,
    updateSurveysOverviewDateSelection,
    dateText,
    surveysOverviewDateText,
    getDateLabel,
    getSurveysOverviewDateLabel,
    getCustomerMovementTrendDateLabel,
    setupDateFromQueryDateString,
    setupSurveysOverviewDateFromQueryDateString,
    previousDate,
    nextDate,
    isLatestAggregation,
    previousSurveysOverviewDate,
    nextSurveysOverviewDate,
  };
});
